import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toast } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Patient } from '../../models/patients';
import { formatPhoneNumber } from '../../models/common';
import { getValiadatedDate } from '../../models/utils';
import 'react-datepicker/dist/react-datepicker.css';
import { Case, InboundChannel } from '../../models/cases';
import { updatePatient } from '../../services/patient.service';
import '../../assets/css/call-detail.scss';
import '../../assets/css/patient-detail.scss';


type PatientInfoProps = {
  patient: Patient;
  case?: Case;
  onModified?: Function;
  onSave?: Function;
};


const PatientInfo = React.memo<PatientInfoProps>((props) => {
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [contactError, setError] = useState();
  const [errorMsg, setErrorMsg] = useState('');

  const toggleShowA = () => {
    setShowA(!showA);
    setTimeout(() => setShowA(!showA), 5000);
  };

  const toggleShowB = (statusToDisplay = '') => {
    if (errorMsg) {
      if (statusToDisplay === '') {
        setShowB(!showB);
        setTimeout(() => setShowB(!showB), 5000);
      } else {
        setShowB(true);
        setTimeout(() => setShowB(false), 5000);
      }
    }
  };

  const [patient, setPatient] = useState<Patient>({
    id: 0,
    dispensaryId: 0,
    firstName: '',
    lastName: '',
    caregiver: '',
    emailAddress: '',
    phoneNumber: '',
    mmccNumber: '',
    mmccExpDate: undefined,
    certificateExpDate: undefined,
  });

  const {
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    mmccNumber,
    mmccExpDate,
    certificateExpDate,
  } = useMemo(
    () => ({
      ...patient,
      phoneNumber: patient.phoneNumber || '+1',
      dispensaryId: patient.dispensary ? patient.dispensary.id : 0,
      mmccExpDate: getValiadatedDate(patient.mmccExpDate, new Date()),
      certificateExpDate: getValiadatedDate(
        patient.certificateExpDate,
        undefined,
      ),
    }),
    [patient],
  );

  const isEmail = useMemo(
    () => props.case && props.case.inboundChannel === InboundChannel.EMAIL,
    [props.case],
  );

  const isPhone = useMemo(
    () => props.case && props.case.inboundChannel === InboundChannel.PHONE,
    [props.case],
  );

  useEffect(() => {
    if (props.patient) {
      setPatient(props.patient);
    }
  }, [props.patient]);

  const onChange = useCallback(
    (e) => {
      if(props.onModified) props.onModified();
      setPatient({ ...patient, [e.target.name]: e.target.value });
      // TODO VALIDATE EMAIL AND PHONE
      setErrorMsg('');
      if (e.target.name === 'emailAddress') {
        if (
          !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/i).test(
            e.target.value,
          )
        ) {
          setErrorMsg('Invalid Email');
          toggleShowB();
        }
      } else if (e.target.name === 'phoneNumber') {
        if (
          !new RegExp(
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
          ).test(e.target.value) ||
          new RegExp(
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
          ).test(e.target.value) ||
          new RegExp(/\+?[0-9]{11}/).test(e.target.value)
        ) {
          setErrorMsg('Invalid Phone');
          toggleShowB();
        }
      }
    },
    [patient],
  );

  const onChangeCertDate = useCallback(
    (e) => setPatient({ ...patient, certificateExpDate: e }),
    [patient],
  );

  const onChangeMmccDate = useCallback(
    (e) => setPatient({ ...patient, mmccExpDate: e }),
    [patient],
  );

  const onUpdatePatient = useCallback(async () => {
    if (errorMsg) {
      toggleShowB('Display');
    } else {
      try {
        const res: any = await updatePatient(
          patient.dispensary ? patient.dispensary.id : 0,
          patient,
        );
        setShowA(!showA);
        setPatient(res);
        if(props.onSave) props.onSave();
      } catch (error) {
        setError(error);
      }
    }
  }, [patient, showA]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="outer-container">
      {contactError ? contactError : null}
      <Toast
        className="outer-container--toast"
        style={{ backgroundColor: 'green', color: 'white' }}
        show={showA}
        onClick={toggleShowA}
        onClose={toggleShowA}
        delay={1500}
        autohide
      >
        <Toast.Body>Patient Info Saved</Toast.Body>
      </Toast>

      <Toast
        className="outer-container--toast"
        style={{ backgroundColor: 'red', color: 'white' }}
        show={showB}
        onClick={() => toggleShowB()}
        onClose={() => toggleShowB()}
        delay={1500}
        autohide
      >
        <Toast.Body>{errorMsg}</Toast.Body>
      </Toast>

      <div className="patient-detail--inner">
        <button type="button" className="save-button" onClick={onUpdatePatient}>
          <FontAwesomeIcon icon={faSave} />
        </button>
        <div className="inner-container">
          <div className="left-style">
            <div className="name-inputs">
              <div className="input-container">
                First Name
                <input
                  className="input-box"
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={onChange}
                />
              </div>
              <div className="input-container">
                Last Name
                <input
                  className="input-box"
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="input-container">
              Email
              <input
                disabled={isEmail}
                className={`input-box input-box-${isEmail}`}
                type="email"
                name="emailAddress"
                value={emailAddress}
                onChange={onChange}
              />
            </div>
            <div className="input-container">
              Telephone
              <MaskedInput
                mask={[
                  '+',
                  /[1-9]/,
                  ' ',
                  '(',
                  /[1-9]/,
                  /\d/,
                  /\d/,
                  ')',
                  ' ',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                disabled={isPhone}
                className={`input-box input-box-${isPhone}`}
                type="text"
                name="phoneNumber"
                value={formatPhoneNumber(phoneNumber)}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="right-style">
            <div>
              <div className="input-container">
                MMCC #
                <input
                  // TODO disabling auto complete is not working
                  autoComplete="off"
                  className="input-box"
                  type="text"
                  name="mmccNumber"
                  value={mmccNumber}
                  onChange={onChange}
                />
              </div>
            </div>
            <div>
              <div className="input-container input-container--date">
                <p>MMCC # Exp Date</p>
                <DatePicker
                  autoComplete="off"
                  selected={mmccExpDate}
                  onChange={onChangeMmccDate}
                  dateFormat="MM/dd/yyyy"
                />
              </div>
            </div>
            <div>
              <div className="input-container input-container--date">
                <p>Certificate Exp Date</p>
                <DatePicker
                  autoComplete="off"
                  selected={certificateExpDate}
                  onChange={onChangeCertDate}
                  dateFormat="MM/dd/yyyy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PatientInfo;
