import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkAlt,
  faTimesCircle,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';
import { getDispensaryColor } from '../../models/dispensaries';
import { searchProducts, ProductRequest} from '../../services/product.service';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Order } from '../../models/common';
import '../../assets/css/products.scss'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#858b8d',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 700,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

type ProductListProps = {
  dispensaryId?: number;
  from:string;
};


const productParameters = {
  PRODUCT_NAME : 'PRODUCT_NAME',
  CBD : 'CBD',
  PRODUCT_QUANTITY : 'PRODUCT_QUANTITY',
  PRODUCT_TYPE : 'PRODUCT_TYPE',
  STOCK_AVAILABILITY : 'STOCK_AVAILABILITY',
  SELLING_PRICE : 'SELLING_PRICE',
  THC : 'THC'
}

const ProductList = React.memo<ProductListProps>((props) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [products, setProducts] = React.useState();
  const [counts, setCounts] = React.useState(0);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState(productParameters.PRODUCT_NAME);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const params = React.useMemo<ProductRequest>(() : ProductRequest =>({
    dispensaryId: props.dispensaryId
  }),[props]);

  const getProducts = async ()=> {
      const res : any = await searchProducts({
          ...params,
          offset : page * rowsPerPage,
          limit : rowsPerPage,
          order: order,
          orderBy: orderBy,
          getCount : true           
      });
    setCounts(res.count);
    setProducts(res.records);
  }

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (event) event.preventDefault();
    const isAsc = orderBy === property && order === 'asc';
    setProducts(undefined);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };


  useEffect(() => {
      getProducts();
  }, [page, rowsPerPage, order, orderBy, props]);// eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();
  
  const productsTable = () => (
    products!.length > 0 ?(
        <>
        <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
        <TableHead>
            <TableRow>
                <StyledTableCell key={productParameters.PRODUCT_NAME} align="center" style={{width:'80px'}}
                        sortDirection={orderBy === productParameters.PRODUCT_NAME ? order : false} >
                <TableSortLabel
                        active={orderBy === productParameters.PRODUCT_NAME}
                        direction={orderBy === productParameters.PRODUCT_NAME ? order : 'desc'}
                        onClick={createSortHandler(productParameters.PRODUCT_NAME)}
                >
                Product
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell key={productParameters.PRODUCT_TYPE} align="center" style={{width:'80px'}}
                        sortDirection={orderBy === productParameters.PRODUCT_TYPE ? order : false} >
                <TableSortLabel
                        active={orderBy === productParameters.PRODUCT_TYPE}
                        direction={orderBy === productParameters.PRODUCT_TYPE ? order : 'desc'}
                        onClick={createSortHandler(productParameters.PRODUCT_TYPE)}
                >
                Type
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell key={productParameters.THC} align="center" style={{width:'120px'}}
                    sortDirection={orderBy === productParameters.THC ? order : false} >
                <TableSortLabel
                        active={orderBy === productParameters.THC}
                        direction={orderBy === productParameters.THC ? order : 'desc'}
                        onClick={createSortHandler(productParameters.THC)}
                >
                THC
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell key={productParameters.CBD} align="center" style={{width:'120px'}}
                    sortDirection={orderBy === productParameters.CBD ? order : false} >
                <TableSortLabel
                        active={orderBy === productParameters.CBD}
                        direction={orderBy === productParameters.CBD ? order : 'desc'}
                        onClick={createSortHandler(productParameters.CBD)}
                >
                CBD
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell key={productParameters.PRODUCT_QUANTITY} align="center" style={{width:'140px'}}
                    sortDirection={orderBy === productParameters.PRODUCT_QUANTITY ? order : false} >
                <TableSortLabel
                        active={orderBy === productParameters.PRODUCT_QUANTITY}
                        direction={orderBy === productParameters.PRODUCT_QUANTITY ? order : 'desc'}
                        onClick={createSortHandler(productParameters.PRODUCT_QUANTITY)}
                >
                Quantity
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell key={productParameters.SELLING_PRICE} align="center" style={{width:'100px'}}
                    sortDirection={orderBy === productParameters.SELLING_PRICE ? order : false} >
                <TableSortLabel
                        active={orderBy === productParameters.SELLING_PRICE}
                        direction={orderBy === productParameters.SELLING_PRICE ? order : 'desc'}
                        onClick={createSortHandler(productParameters.SELLING_PRICE)}
                >
                Selling Price
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell key={productParameters.STOCK_AVAILABILITY} align="center" style={{width:'80px'}}
                    sortDirection={orderBy === productParameters.STOCK_AVAILABILITY ? order : false} >
                <TableSortLabel
                        active={orderBy === productParameters.STOCK_AVAILABILITY}
                        direction={orderBy === productParameters.STOCK_AVAILABILITY ? order : 'desc'}
                        onClick={createSortHandler(productParameters.STOCK_AVAILABILITY)}
                >
                Stock
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="center" style={{width:'80px'}}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products!.map((product : any) => (
              <StyledTableRow key={`${product.id}-${product.dispensary.id}`}>
                <StyledTableCell component="th" scope="row" align="center">
                  <span className="products-table-data">
                    {product.productName || ''}
                  </span>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  <span className="products-table-data">
                    {product.vendor ||''}
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span className="products-table-data">
                   {product.thc}
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span className="products-table-data">
                    {product.cbd}
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span className="products-table-data">
                    {product.quantity}
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span className="products-table-data">
                  {product.sellingPrice}
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  
                  {(product.stock? <span className="products-table-data green"><FontAwesomeIcon icon={faCheckCircle}/> Available</span> : <span className="patients-table-data red"><FontAwesomeIcon icon={faTimesCircle}/> Unavailable</span>)} 
                  
                </StyledTableCell>                        
                <StyledTableCell align="left">
                  <Link
                        className={`link-color-${getDispensaryColor(product.dispensary)}`}
                        // to={`/products/${product.id}/dispensaries/${product.dispensary!.id}`}
                        to =''
                      >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                      </Link>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
            rowsPerPageOptions={[50,100,200]}
            component="div"
            count={counts}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      <div>&nbsp;</div>
        </>
        )
    :(
      <div className="empty-table-message">
        No products to display
      </div>)
  );
  return (products? (     
        <div className="products">
              {productsTable()}
            </div>
    )
    :(
        <div className="table-loading-container">
          <Spinner animation="border" />
        </div>
    )
  );
});

export default ProductList;