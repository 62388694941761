import { Spinner, Badge, Card, Row, Col, ProgressBar } from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react';
import CallQueue from '../Dashboard/CallQueue/CallQueue';
import QueueCaseList from '../Dashboard/Queue.CaseList';
import { AuthContext, SidePanelContext } from '../../models/common';
import { authContext as _authContext } from '../../context/auth/authContext';
import { CaseContext } from '../../models/cases';
import { caseContext as _caseContext } from '../../context/case/caseContext';
import { sidePanelContext as _sidePanelContext } from '../../context/sidePanel/sidePanelContext';
import { Doughnut, Chart } from 'react-chartjs-2';
import { apiUrl } from '../../../src/config/environments';
import DatePicker from 'react-datepicker';
import '../../assets/css/statistics.scss';
import { CardBody } from 'react-bootstrap/Card';
import { printReservationMethod } from '../../models/utils';

const axios = require('axios');

const questionAnswers = [
  {
    question: 'Do you accept MMC cards from other states?',
    answer: 'Maybe an answer here for easy access to the pertinant information? Might change from state to state so perhaps should be a link to main FAQ page?'
  },
  {
    question: 'How can I get my MMC card?',
    answer: 'Maybe an answer here for easy access? Maybe there will be a few different answers depending on state?'
  },
  {
    question: 'What is the strongest strain available',
    answer: 'Maybe an answer here for easy access? Maybe there will be a few different answers depending on the state?'
  },
]

const getCategoryName = (category) => {
  switch(category){
        case 'ADVERSE_EVENT':
          return 'Adverse Event';
        case 'INVENTORY':
          return 'Inventory'
        case 'PRODUCT_INFO':
          return 'Product Information'
        case 'MEDICAL_INQ':
          return 'Medical Inquiry'
        case 'GENERAL_INQ':
          return 'General Inquiry'
        default :
          return null;
   } 
}


const parseData = (input) => {
  let labels : any = [];
  let data : any = []
  for(var i in input) {
    labels.push(getCategoryName(input[i].CategoryName!));
    data.push(Number(input[i]!.Percentage).toFixed(2));
  }
  const returnData = {
    type:'pie',
    renderAt: 'chart-container',
    labels,
    legend: {
      position: 'bottom',
    },
    datasets: [
      {
        label: '# of Votes',
        
        chart: {
          "caption": "Split of Revenue by Product Categories",
          "subCaption": "Last year",
          "numberPrefix": "$",
          "bgColor": "#ffffff",
          "startingAngle": "310",
          "showLegend": "1",
          "defaultCenterLabel": "Total revenue: $64.08K",
          "centerLabel": "Revenue from $label: $value",
          "centerLabelBold": "1",
          "showTooltip": "0",
          "decimals": "0",
          "theme": "fusion"
        },
        data,
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  
  return returnData;
}


const  TopRequestedProduct = ({strainName, productType,percentage,sales}) => {
  return(
    <div className="top-requested-product">
      <table>
        <tr>
          <td className="strain-name">{strainName}</td>
          <td rowSpan={2} className="percentage">{percentage} % </td>
        </tr>
        <tr>
          <td className="product-type">{productType}</td>
        </tr>
        <tr>
          <td colSpan={2} className="product-progress">
            <ProgressBar variant="success" now={percentage} />
          </td>
        </tr>
        <tr>
          <td colSpan={2} className="sales-number">{sales} Sales</td>
        </tr>
      </table>
    </div>
  );
}

const Statistics = () => {
  const authContext: AuthContext = useContext(_authContext);
  const { user } = authContext;
  const caseContext: CaseContext = useContext(_caseContext);
  const getCases = caseContext.getCases!;
  const sidePanelContext: SidePanelContext = useContext(_sidePanelContext);
  const { agentStatus} = sidePanelContext;
  const [displayWarning, setdisplayWarning] = useState(true);

  const [topRequestedProduct, setTopRequestedProduct] = useState<any>([]);
  const [todaysReservations, setTodaysReservations] = useState<any>([]);
  const [categoryData, setCategoryData] = useState<any>([]);

  useEffect(() => {
    let params = JSON.stringify({
      "dispensaryID": 1
    });
     
    let config = {
      method: 'post',
      url: `${apiUrl}/dashboard/`,
      data : params
    };
     
    axios(config)
    .then((response) => {
      console.log(response.data.Reservations);
      const data = JSON.parse(response.data.Reservations)
      console.log("Data",data);
      setTopRequestedProduct(data);
    })
    .catch((error) => {
      console.log(error);
    });
  },[])

  useEffect(() => {
    let params = JSON.stringify({
      "dispensaryID": 1
    });
     
    let config = {
      method: 'post',
      url: `${apiUrl}/dashboard/`,
      data : params
    };
     
    axios(config)
    .then((response) => {
      console.log(response.data.Reservations);
      const data = JSON.parse(response.data.Reservations)
      console.log("Data",data);
      setTopRequestedProduct(data);
    })
    .catch((error) => {
      console.log(error);
    });


    var data = JSON.stringify({
      "dispensaryId": 1
    });
     
    var config1 = {
      method: 'put',
      url: 'https://brq1hlf5m8.execute-api.us-east-1.amazonaws.com/dev/dashboard',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
     
    axios(config1)
    .then(function (response) {
      console.log("Table Data",response.data.reservations);
      let newReservation = JSON.parse(response.data.reservations);
      setTodaysReservations(newReservation);
      console.log("Table Data2",newReservation);
    })
    .catch(function (error) {
      console.log(error);
    });

    var data = JSON.stringify({
      "dispensaryId": 1
    });
     
    var config1 = {
      method: 'patch',
      url: 'https://brq1hlf5m8.execute-api.us-east-1.amazonaws.com/dev/dashboard',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
     
    axios(config1)
    .then(function (response) {
      console.log("Category Data",response.data);
      let newReservation = JSON.parse(response.data);
      setCategoryData(newReservation);
      console.log("Category Data2",newReservation);
    })
    .catch(function (error) {
      console.log(error);
    });


  },[])

  const now = 70;

  const options = {
    legend: {
      position: 'left',
    },
    plugins: {
      datalabels: {
          color: '#111',
          textAlign: 'center',
          font: {
              lineHeight: 1.6
          },
          formatter: function(value, ctx) {
              return ctx.chart.data.labels[ctx.dataIndex] + '\n' + value + '%';
          }
      }
    }
  }

  return (
    <div className="padding-left dashboard">
      {
        todaysReservations.length > 0 && (
          <Row>
            <Col>
              <h3 style={{marginTop:'40px', marginBottom: '30px',textAlign:'left'}}>
                Welcome back{(user && user!.agentname)? `, ${user!.agentname}` : ''}
              </h3>
              <Card style={{marginTop:'unset'}}>
                <Card.Header>
                  <h5>
                    <b style={{lineHeight:2}}>Today's Reservation
                      <Badge
                        pill
                        variant='success'
                        style={{marginLeft:'10px'}}
                      >
                        {todaysReservations.length}
                      </Badge>
                    </b>
                  </h5>
                </Card.Header>
                <Card.Body>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Reserve Time</th>
                        <th scope="col">Item</th>
                        <th scope="col">Count</th>
                        <th scope="col">Method</th>
                        <th scope="col">Rep Name</th>
                        <th scope="col">Case Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        todaysReservations.slice(0, 5).map((item) =>
                          <tr>
                            <td>{item.FirstName}</td>
                            <td>{item.LastName}</td>
                            <td>{new Date(item.ReservationDate).toLocaleDateString()}</td>
                            <td>{item.ProductName}</td>
                            <td>{item.ItemCount}</td>
                            <td>{printReservationMethod(item.Method)}</td>
                            <td>{item.Representative}</td>
                            <td>{item.caseId}</td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )
      }
      <Row>
        <Col>
          <Card>
            <Card.Body className="d-flex flex-row">
              <div className="d-flex flex-row align-items-center flex-grow-1 text-left pl-4"><h5>Insights</h5></div>
              <div className="d-flex flex-row align-items-center p-3">
                <DatePicker
                  className="date-range-buttons"
                  autoComplete="off"
                  selected={new Date()}
                  dateFormat="MM/dd/yyyy"
                  style={{}}
                />
                <span className="mr-2 ml-2">to</span> 
                <DatePicker
                  className="date-range-buttons"
                  autoComplete="off"
                  selected={new Date()}
                  dateFormat="MM/dd/yyyy"
                  style={{}}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{maxHeight:'500px', height:'500px', marginTop:'unset'}}>
            <Card.Header>
              <h5>
                <b>By Categories</b>
              </h5>
            </Card.Header>
            <Card.Body style={{padding:'10px'}}>
            <Doughnut 
              data={parseData(categoryData)}
              className="doughnut-chart"
              type="pie"
              options= {options}      
            />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{maxHeight:'500px', height:'500px', marginTop:'unset'}}>
            <Card.Header>
              <h5>
                <b>By Topic</b>
              </h5>
            </Card.Header>
            <Card.Body className="d-flex align-items-center question-answer-background">
              {questionAnswers.length && questionAnswers.map((item) => 
                <p className="question-answer-box">
                  <span className="question">{item.question}</span>
                  <span className="answer">{item.answer}</span>
                </p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="d-flex">
        <Col style={{flex:1}}>
          <Card style={{marginTop:'unset', height:'100%'}}>
            <Card.Header>
              <h5>
                <b>Top Requested Products</b>
              </h5>
            </Card.Header>
            <Card.Body className="top-requested-products">
              {
                topRequestedProduct.length > 0 ? (
                  topRequestedProduct.map((item) => 
                    <TopRequestedProduct
                      strainName={item.ProductType}
                      percentage={(item.Percentage).toFixed(1)}
                      sales={item.ReservationCount}
                      productType={item.ProductName}
                    />
                  )
                ) : (
                  <li className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" />
                  </li>
                )
              }
            </Card.Body>
          </Card>
        </Col>
        <Col style={{maxWidth:'45%', flex:1}}>
          <Card style={{marginTop:'unset', height:'100%'}}>
            <Card.Header>
              <h5>
                <b>By Product Type</b>
              </h5>
            </Card.Header>
            <Card.Body>
              <ul className="chart-list">
                { (topRequestedProduct.length > 0) ?
                    topRequestedProduct.map((items) =>       
                    <li>
                      <b>{items.ProductType}</b>
                      <ProgressBar striped now={(items.Percentage).toFixed(1)} label={`${(items.Percentage).toFixed(1)} %`} />
                    </li>
                  ): 
                  <li className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" />
                  </li>
                }
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );

};

export default Statistics;
