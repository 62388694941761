import React, { useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { CaseCreate, CaseSkill, InboundChannel } from '../../models/cases';
import { caseContext as CaseContext } from './caseContext';
import caseReducer from './caseReducer';
import {
  GET_CASES_SUCCESS,
  GET_CASES_FAIL,
  CREATE_CASE_SUCCESS,
  CREATE_CASE_FAIL,
  GET_DISPENSARY_CASES_SUCCESS,
  GET_PATIENT_CASSES_SUCCESS,
  CLEAR_CASES,
} from '../types';

import { apiUrl } from '../../config/environments';

import { ContactContext } from '../../models/common';
import { contactContext as _contactContext } from '../contact/contactContext';

const CaseState = (props) => {
  const initialState = {
    pendingCases: null,
    outboundCases: null,
    inProgressCases: null,
    patientCases: null,
    topics: [],
  };

  const contactContext: ContactContext = useContext(_contactContext);
  const setContactCase = contactContext.setContactCase!;

  const [state, dispatch] = useReducer(caseReducer, initialState);

  const getCases = async (skill: CaseSkill) => {
    try {
      const res = await axios.get(`${apiUrl}/cases`);
      const allCases = res.data.reverse();;

      const dispensaryCases = res.data.reverse();

      const sortedCases = allCases.reduce((acc, c) => {
        if (c.skill === skill) {
          if (['PENDING'].includes(c.status!)) {
            if (['INBOUND'].includes(c.state!)) {
              if (!(c.patientDetailsPending && c.inboundChannel === InboundChannel.EMAIL)) {
                acc.pendingCases.push(c);
              }
            } else if (['OUTBOUND'].includes(c.state!)) {
              acc.outboundCases.push(c);
            }
          } else if (['IN_PROGRESS'].includes(c.status!)) {
            acc.inProgressCases.push(c);
          }
        }
        return acc;
      }, {
        pendingCases: [],
        outboundCases: [],
        inProgressCases: [],
      });
      dispatch({
        type: GET_CASES_SUCCESS,
        payload: { allCases, dispensaryCases, ...sortedCases },
      });
    } catch (err) {
      dispatch({
        type: GET_CASES_FAIL,
        payload: err.message,
      });
    }
  };
  const getPatientCases = async (dispensaryId: number, patientId: number, caseNumber: number, parentCaseNumber: number) => {
    try {
      const res = await axios.get(
        `${apiUrl}/dispensaries/${dispensaryId}/patients/${patientId}/cases?db=MySQL`
      );
      const relatedCases = caseNumber ? res.data
        .filter(c => (parentCaseNumber && c.caseNumber === parentCaseNumber)
          || ((parentCaseNumber && c.parentCaseNumber === parentCaseNumber) && (caseNumber && c.caseId !== caseNumber))
          || (caseNumber && c.parentCaseNumber === caseNumber)
        ) : res.data
      dispatch({
        type: GET_PATIENT_CASSES_SUCCESS,
        payload: relatedCases.reverse(),
      })
    } catch (err) {
      dispatch({
        type: GET_CASES_FAIL,
        payload: err.message,
      });
    }
  }
  const getDispensaryCases = async (dispensaryId: number) => {
    try {
      const res = await axios.get(
        `${apiUrl}/dispensaries/${dispensaryId}/cases?db=MySQL`,
      );
      const dispensaryCases = res.data.reverse();
      dispatch({
        type: GET_DISPENSARY_CASES_SUCCESS,
        payload: { dispensaryCases },
      });
    } catch (err) {
      dispatch({
        type: GET_CASES_FAIL,
        payload: err.message,
      });
    }
  };

  const createCase = async (dispensaryId: number, caseCreate: CaseCreate) => {
    try {
      const res = await axios.post(
        `${apiUrl}/dispensaries/${dispensaryId}/cases`,
        caseCreate,
      );

      dispatch({
        type: CREATE_CASE_SUCCESS,
        payload: res,
      });
    } catch (err) {
      dispatch({
        type: CREATE_CASE_FAIL,
        payload: err.message,
      });
    }
  };

  const getCase = async (dispensaryId: number, caseNumber: number) => {
    const res = await axios.get(
      `${apiUrl}/dispensaries/${dispensaryId}/cases/${caseNumber}?db=mySQL`);
    setContactCase(res.data);
  };

  const getDownload = async (s3Location: string) => {
    const res = await axios.get(`${apiUrl}/download?s3Location=${s3Location}`);
    return res.data;
  };

  const clearCases = async () => {
    dispatch({
      type: CLEAR_CASES,
    });
  };

  const deleteCase = async (dispensaryId: number, caseNumber: number) => {
    return new Promise(async (resolve) => {
      await axios.delete(`${apiUrl}/dispensaries/${dispensaryId}/cases/${caseNumber}?db=Mysql`);
      resolve();
    })
  };

  return (
    <CaseContext.Provider
      value={{
        allCases: state.allCases,
        patientCases: state.patientCases,
        pendingCases: state.pendingCases,
        outboundCases: state.outboundCases,
        inProgressCases: state.inProgressCases,
        dispensaryCases: state.dispensaryCases,
        allTopics: state.allTopics,
        getCases,
        createCase,
        getCase,
        getDownload,
        getDispensaryCases,
        getPatientCases,
        clearCases,
        deleteCase,
      }}
    >
      {props.children}
    </CaseContext.Provider>
  );
};

export default CaseState;

CaseState.propTypes = {
  children: PropTypes.shape({}),
};

CaseState.defaultProps = {
  children: {},
};
