import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import cleanDeep from 'clean-deep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhoneAlt,
  faEnvelope,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Spinner} from 'react-bootstrap';
import { getFormattedDateTime, getContactName, printStatus} from '../../models/utils'
import { getDispensaryColor } from '../../models/dispensaries';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { searchRelatedCases } from '../../services/case.service';
import '../../assets/css/cases.scss';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#858b8d',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 700,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

type RelatedCaseListProps = {
    dispensaryId?: number;
    patientId?: number;
    caseId?: number;
    parentCaseId?: number;
  };
  
  
const RelatedCaseList = React.memo<RelatedCaseListProps>((props) => {
  
  const classes = useStyles();
  const [cases, setCases] = useState([]);
  const getRelatedCases = async ()=> {
    
    const params = {
        dispensaryId: props.dispensaryId,
        caseId: props.caseId,
        parentCaseId: props.parentCaseId,
        relatedCase: true
    };

    const result = await searchRelatedCases({
        ...cleanDeep(params),
        offset : 0,
        limit : 10000,
    });
    setCases(result||[]);
 
  }

  useEffect(() => {
    getRelatedCases();
  }, [props]);// eslint-disable-line react-hooks/exhaustive-deps
  
  const casesTable = () => (cases && cases.length ? (
    <>
    <TableContainer component={Paper}>
    <Table className={classes.table} aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell align="right" style={{width:'40px'}} >CASE</StyledTableCell>
          <StyledTableCell align="right" style={{width:'100px'}}>CREATED DATE</StyledTableCell>
          <StyledTableCell align="right" style={{width:'60px'}}>TYPE</StyledTableCell>
          <StyledTableCell align="right" style={{width:'200'}}>PATIENT</StyledTableCell>
          <StyledTableCell align="right" style={{width:'160'}}>DISPENSARY</StyledTableCell>
          <StyledTableCell align="right" style={{width:'80'}}>SKILL</StyledTableCell>
          <StyledTableCell align="right" style={{width:'100px'}}>STATUS</StyledTableCell>
          <StyledTableCell align="right" style={{width:'80px'}}></StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {cases.map((data : any) => (
          <StyledTableRow key={data.caseNumber}>
            <StyledTableCell component="th" scope="row" align="right">
            {data.caseNumber}
            </StyledTableCell>
            <StyledTableCell align="right">
                <span className="case-list-date">
                  {getFormattedDateTime(data.createdDateTime)}
                </span>
            </StyledTableCell>
            <StyledTableCell align="right">
            <span className="no-wrap">               
                {data.inboundChannel === 'PHONE' ? (
                  <FontAwesomeIcon icon={faPhoneAlt} />)
                  : (
                    <FontAwesomeIcon icon={faEnvelope} />
                  )}
                &nbsp;
                  {
                  data.state === 'INBOUND' ? (
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                  )
                    : (
                      <FontAwesomeIcon icon={faLongArrowAltRight} />
                    )
                }
              </span>
            </StyledTableCell>
            <StyledTableCell align="right">
              <span className="cases-table-data--name">
              <Link to={`/patients/${data.patient.id}/dispensaries/${data.dispensary.id}`}>
                {getContactName(data)}
              </Link>
              </span>
            </StyledTableCell>
            <StyledTableCell align="right">
              <span className={`cases-table-data--dispensary background-color-${getDispensaryColor(data.dispensary)}`}>
              <Link to={`/dispensaries/${data.dispensary.id}`}>
                {data.dispensary.name.slice(0, 12)}
              </Link>
              </span>
            </StyledTableCell>
            <StyledTableCell align="right">
              {data.skill}
            </StyledTableCell>
            <StyledTableCell align="right">
              <span className="cases-table-data--status">
                  {data.status && printStatus(data)}
              </span>
            </StyledTableCell>
            <StyledTableCell align="left">
              <Link
                    className={`link-color-${getDispensaryColor(data.dispensary)}`}
                    to={`/dispensaries/${data.dispensary.id}/cases/${data.caseNumber}`}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </Link>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
   </TableContainer>
   <div>&nbsp;</div>
    </>
  )
    : (
      <div className="empty-table-message">No cases to display</div>
    ));

  return  cases ? (
    <div className="cases" style={{padding: 0}}>
      {casesTable()}
    </div>
  )
    : (
      <div
        className='table-loading-container cases-margin-left-false'
      >
        <Spinner animation="border" />
      </div>
    );
});

export default RelatedCaseList;
