import React, { useEffect, useState, useCallback, useMemo,useContext } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tab, Tabs, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import PatientInfo from './PatientInfo';
import CaseList from '../Cases/CaseList';
import DispensaryInfo from '../Dispensaries/DispensaryInfo';
import { Patient } from '../../models/patients';
import {searchPatients, deletePatient} from '../../services/patient.service'
import { userInfo } from 'os';
import { AuthContext } from '../../models/common';
import { authContext as _authContext } from '../../context/auth/authContext';

const PatientDetail = (props) => {
  const authContext: AuthContext = useContext(_authContext);
  const [patient, setPatient] = useState<Patient>();
  const patientCasesStyle = {
    marginTop: '1rem',
  };
  const [showConfirm, setShowConfirm] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState('');

  const { user } = authContext;

  const { patientId, dispensaryId } = useMemo(
      ()=>({
        patientId: parseInt(props.match.params.patientId),
        dispensaryId: parseInt(props.match.params.dispensaryId)
      }), [props.match.params.patientId, props.match.params.dispensaryId]
  );

  const getPatient = async ()=> {
    try {
      const res = await searchPatients({
          dispensaryId: dispensaryId,
          patientId : patientId,
          offset: 0,
          limit: 1
      });

      setPatient(res[0]||undefined);
    }
    catch(e) {
      setError(JSON.stringify(e));
    }
  }


  useEffect(() => {
      getPatient();
  }, [props]);// eslint-disable-line react-hooks/exhaustive-deps

  const renderRedirect = useCallback(() => {
    if (redirect) {
      return <Redirect to="/patients" />;
    }
    return null;
  },[redirect]);

  return patient ? (
    <div
      className="padding-left patient-parent"
    >
      <div className="dispensary-info--parent">
        <Tabs defaultActiveKey="patient" id="call-details">
          <Tab eventKey="patient" title="Patient Details">
            <PatientInfo patient={patient} />
          </Tab>
          <Tab
            eventKey="dispensary"
            title={
              `${patient.dispensary ? patient.dispensary.name : 'Dispensary'} Profile`
            }
          >
            <DispensaryInfo dispensary={patient.dispensary} />
          </Tab>
        </Tabs>
        <div style={{ display: 'flex', marginTop: '1rem' }}>
        { user && user.groups[0] === 'CD' &&
          <button
            type="button"
            className="delete-button"
            onClick={() => {
              setShowConfirm(true)
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>}
        </div>
      </div>
      <div style={patientCasesStyle}>
        <Tabs defaultActiveKey="patientCases" id="call-details">
          <Tab
            eventKey="patientCases"
            title="Cases"
          >
            <CaseList
              dispensaryId={(patient && patient.dispensary)? patient.dispensary.id : 0}
              patientId={patient ? patient.id : 0}
              from='patient'
            />
          </Tab>
        </Tabs>
        {renderRedirect()}
        <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
          <Modal.Body>Are you sure you want to delete this patient?</Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="cases-button silver-cases-button"
              onClick={() => setShowConfirm(false)}
            >
              No
              </button>
            <button
              type="button"
              className="cases-button silver-cases-button"
              onClick={async () => {
                await deletePatient(patient!.dispensary!.id, patient!.id);
                setShowConfirm(false);
                setRedirect(true);
              }}
            >
              Yes
          </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  ) : (null)
};

export default PatientDetail;

PatientDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      patientId: PropTypes.string,
      dispensaryId: PropTypes.string
    }),
  }),
};

PatientDetail.defaultProps = {
  match: {},
};
