/* eslint-disable react/prop-types */

import React from 'react';
import '../../assets/css/dispensaries.scss';

const DispensaryInfo = (props) => {
  let holderLogo = (
    <img
      src="https://ebbflowcreative.com/wp-content/uploads/2018/08/CULTA_ID.png"
      alt="company logo"
    />
  );
  if (props.dispensary.name === 'Curaleaf') {
    holderLogo = (
      <img
        src="https://www.curaleaf.com/wp-content/uploads/2018/08/Curaleaf_2ColorLogo-01-1-2.png"
        alt="company logo"
      />
    );
  }
  const dispensaryInfoPanel = () => (
    <span className="dispensary-details--info-panel">
      <span className="dispensary-details--info-panel-item">
        <span className="dispensary-details--info-panel-item-tag">Manager</span>
        <span className="dispensary-details--info-panel-item-prop">
          {props.dispensary.manager?props.dispensary.manager:'N/A'}
        </span>
      </span>
      <span className="dispensary-details--info-panel-item">
        <span className="dispensary-details--info-panel-item-tag">Email</span>
        <span className="dispensary-details--info-panel-item-prop">
          {props.dispensary.managerEmail?props.dispensary.managerEmail:'N/A'}
        </span>
      </span>
      <span className="dispensary-details--info-panel-item">
        <span className="dispensary-details--info-panel-item-tag">Phone</span>
        <span className="dispensary-details--info-panel-item-prop">
          {props.dispensary.managerPhoneNumber?props.dispensary.managerPhoneNumber:'N/A'}
        </span>
      </span>
      <span className="dispensary-details--info-panel-item">
        <span className="dispensary-details--info-panel-item-tag">Website</span>
        <span className="dispensary-details--info-panel-item-prop">
          {props.dispensary.website?props.dispensary.website:'N/A'}
        </span>
      </span>
    </span>
  );
  const hoursBox = () => {
    const workHours = props.dispensary.others.workhours;
    return (
    <div className="dispensary-details--info-panel-hoursbox">
      {workHours?workHours.map( (e) => 
        (
        <span key={e.day} className="dispensary-details--info-panel-hoursbox-day">
          <span>{e.day}</span>
          <span className="dispensary-details--info-panel-item-hours">
          {e.status === 'OPEN'?(`${e.open}-${e.close}`):`Close`}
          </span>
        </span>  
        )     
      ):'Work Hours'}
    </div>)
  }

  const dispensaryAddressPanel = () => (
    <span className="dispensary-details--info-panel">
      <span className="dispensary-details--info-panel-item">
        <span className="dispensary-details--info-panel-item-tag">Address</span>
        <span className="dispensary-details--info-panel-item-prop">
          {props.dispensary.address.addressLine1}
        </span>
        <span className="dispensary-details--info-panel-item-prop">
          {props.dispensary.address.city}
          ,
          {' '}
          {props.dispensary.address.state}
          {' '}
          {props.dispensary.address.zipCode}
        </span>
      </span>
      <span className="dispensary-details--info-panel-item">{hoursBox()}</span>
    </span>
  );
  const accountPanel = () => (
    <span className="dispensary-details--info-panel">
      <span className="dispensary-details--info-panel-item">
        <span className="dispensary-details--info-panel-item-tag">Account</span>
        <span className="dispensary-details--info-panel-item-prop">
          {props.dispensary.contactPerson?props.dispensary.contactPerson:'N/A'}
        </span>
      </span>
      <span className="dispensary-details--info-panel-item">
        <span className="dispensary-details--info-panel-item-tag">Email</span>
        <span className="dispensary-details--info-panel-item-prop">
          {props.dispensary.contactCenterEmail?props.dispensary.contactCenterEmail:'N/A'}
        </span>
      </span>
      <span className="dispensary-details--info-panel-item">
        <span className="dispensary-details--info-panel-item-tag">Phone</span>
        <span className="dispensary-details--info-panel-item-prop">
        {props.dispensary.contactCenterPhoneNumber?props.dispensary.contactCenterPhoneNumber:'N/A'}
        </span>
      </span>
      <span className="dispensary-details--info-panel-item">
        <span className="dispensary-details--info-panel-item-tag">
          Service Renewal Date
        </span>
        <span className="dispensary-details--info-panel-item-prop">
          {props.dispensary.serviceRenewalDate
            ? props.dispensary.serviceRenewalDate.split(' ')[0]
            : 'N/A'}
        </span>
      </span>
    </span>
  );
  const dispensaryPanel = () => (
    <div className="dispensary-details">
      <div>

        <span className="dispensary-logo">{holderLogo}</span>
        {/* <section className="dispensary-details--info"> */}
        <section className="dispensary-details--account">
          {dispensaryInfoPanel()}
        </section>
      </div>
      <div>
        {/* </section> */}
        <section className="dispensary-details--address">
          {dispensaryAddressPanel()}
        </section>
        <section className="dispensary-details--account">
          {accountPanel()}
        </section>
      </div>
    </div>
  );
  return (
    <section className="calldetails-slider--grid--top">
      {dispensaryPanel()}
    </section>
  );
};

export default DispensaryInfo;
