import cleanDeep from 'clean-deep';
import axios, { AxiosRequestConfig } from 'axios';
import { apiUrl } from '../config/environments';
import { Product } from '../models/products';


export interface ProductRequest {
    dispensaryId?: number;
    productId?: number;
    dispensaryName?: string; 
    productName?: string;
    productType?: string;
    brand?: string; 
    quantity?: string;
    vendor?: string;
    cbd?: string;
    thc?: string;
    sellingPrice?: string;
    stock?: boolean;


    getCount?: boolean;
    offset?: number;
    limit?: number;
    order?:string;
    orderBy?:string;
};




//TODO remove during actual implementation

async function readProductsJSON()
{
  const jsonObj = require('../productsData.json')
  console.log("JSON",jsonObj);
  let data = jsonObj.data
  for(var i in data)
  {
    const desp = {id:4,contactCenterPhoneNumber:'123456',name:'Curaleaf'}
    data[i].dispensary = desp;
  }

  return {
    count : 10,
    records : data
  }
}
//remove till here

export const searchProducts = async ( params : ProductRequest)  : Promise<any> => {
  return readProductsJSON();  
  const res = await axios.get(`${apiUrl}/products`,
    {
      params: {
        ...cleanDeep(params),
      }
    });
    return res.data;
}

export const reserveProducts = async (dispensaryId: number, caseNumber: number, agent, cart) => {
  return new Promise(async (resolve, reject) => {
    try {
      for(var i in cart)
      {
        var data = {
          "dispensaryId": dispensaryId,
          "caseId": caseNumber,
          "productId": cart[i].id,
          "quantity": cart[i].quantity,
          "Quantity": cart[i].quantity,
          "createdBy": agent,
          "reservationDate": cart[i].reservationDate,
          "method": cart[i].method,
        };
  
        const res = await axios.post(`${apiUrl}/reservations`,
        {
          params: {
            ...cleanDeep(data),
            relatedCase: true
          }
        });
      }
    }
    catch(err) {
      reject(err);
    }
    finally {
      console.log("Reservation Successful");
      resolve(true);
    }
  })
}

export const updateProduct = async (dispensaryId: number, updatedProduct: Product) : Promise<any> => {
  try {

    const product = {
      ...updatedProduct,
    };
    product.dispensary = undefined;

    const response = await axios.put(
      `${apiUrl}/dispensaries/${dispensaryId}/products/${updatedProduct.id}?db=MySQL`,
      cleanDeep(product),
    );

    return response.data;
  }
  catch(err) {
    ;
  }
  
};

export const deleteProduct = async (dispensaryId: number, productId: number) => {
  return new Promise<void>(async (resolve) => {
    await axios.delete(`${apiUrl}/dispensaries/${dispensaryId}/products/${productId}?db=mysql`);
    resolve();
  })
};

export const reserveProduct = async (data) => {
  const payload = JSON.stringify(data);
  return new Promise<void>(async (resolve) => {
    var config = {
      method: 'post',
      url: 'https://brq1hlf5m8.execute-api.us-east-1.amazonaws.com/dev/reservations',
      headers: { 
        'Authorization': 'Bearer eyJraWQiOiJuazVHcXFpMEZVUThWNWI1cTRWeW00YXpzZUc1S3c1MjBjM3djVnUrcURZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIzOWIxYmUxMS1iM2FhLTQzY2EtYTY5Ny1lNjYyNmVmMWFmMjMiLCJjb2duaXRvOmdyb3VwcyI6WyJDU1IiXSwiZW1haWxfdmVyaWZpZWQiOnRydWUsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX0lDQWJCMEJBaiIsImNvZ25pdG86dXNlcm5hbWUiOiIzOWIxYmUxMS1iM2FhLTQzY2EtYTY5Ny1lNjYyNmVmMWFmMjMiLCJhdWQiOiJhcXYyc2FrMTJzNXNodWJ0dTA1MmRqa3YwIiwiZXZlbnRfaWQiOiI5ZGYwYWMyNi00ZTZkLTQyN2UtOWZlZC01NGY4MTFmMDE2N2IiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYyMjA0Njc0MSwicGhvbmVfbnVtYmVyIjoiKzEyNDA5MzgxMjkwIiwiZXhwIjoxNjIyNzE4NDkyLCJpYXQiOjE2MjI3MTQ4OTIsImVtYWlsIjoiY3NyMUBiaXp0cmFuc2lnaHRzLmNvbSJ9.cGAoGvyy4dMQxa7Df4I91v3hrXhhFD3flJk3KGikmzYJxumE-B7FYUfdFNNKitWXTDHIqeulai70d_geds0f2925HUVDRDzx_FKqNm9i0ooi2qXW2Yr_c6Xo9VEXXCt07E1r0Lyd6QhwSHzHCBY3UrCE7wWdHcLwMI6MT4Bxkr26SzZWRYVQWU2B4hkQvz2NsPPSuLyKFCWETdmTquSPpGU0l36EC0afFH_1YLbLqzvvEHeJ-zlv8R7hTwaSZ8S7JZHyNSxz3Mjw9DvZLCwBPUBaTEM_ECNwljNy8fblIsmimmz80hm1sAMAe-rmhVNa3Fzxbq1-udVUK75aCiML4g', 
        'Content-Type': 'application/json'
      },
      data 
    };
    // axios()
    //   .then(function (response) {
    //     console.log(JSON.stringify(response.data));
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  })
};