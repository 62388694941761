import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Tab, Tabs, Modal, Button } from 'react-bootstrap';
import DispensaryInfo from '../Dispensaries/DispensaryInfo';
import ContactInfo from './ContactInfo';
import PatientInfo from '../Patients/PatientInfo';
import ProductsList from '../Products/ProductList';
import CaseList from '../Cases/CaseList';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/contact-detail.scss';
import '../../assets/css/modal.scss';

import { ContactContext } from '../../models/common';
import { contactContext as _contactContext } from '../../context/contact/contactContext';
import ProductsCart from '../Products/ProductsCart';

// for testing purposes pull call from here instead of ContactContext
// import contactCase from './assets/dummyCall';

const DiscardChangesWarning = ({onExit, onClose, show}) =>  {
  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <strong>Discard Changes?</strong>
        </Modal.Header>
        <Modal.Body>Looks like you have made some changes to pateint details. Please save the changes before leaving</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onExit}>
            Exit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


type ContactDetailProps = {
  showContactDetails: boolean;
};

const ContactDetail = React.memo<ContactDetailProps>((props) => {


  const [detailsChanged, setDetailsChanged] = useState(false);
  const [detailsSaved, setDetailsSaved] = useState(false);
  const [showChangesWarning, setShowChangesWarning] = useState(false);


  const contactContext: ContactContext = useContext(_contactContext);
  const { contactCase } = contactContext;
  const { showContactDetails } = props;

  const slideoutWidth: string = useMemo(() => {
    if (props.showContactDetails) {
      return contactCase && contactCase.inboundChannel === 'PHONE' ? 'calc(100% - 32rem)' : 'calc(100% - 37rem)';
    }
    return '0';
  }, [props.showContactDetails, contactCase]);

  const slideoutRight: string = useMemo(
    () => (contactCase && contactCase.inboundChannel === 'PHONE' ? '15rem' : '20rem'),
    [contactCase]);
  return (
    <>
      <div
        id="slideOut"
        className={`call-slideout call-slideout-${showContactDetails}`}
        style={{
          // borderRight: showContactDetails ? '1px solid #e3e6ea' : 'none',
          right: slideoutRight,
          width: slideoutWidth
        }}
      >
        {
          contactCase
            ? (
              <div className="calldetails-slider--grid">
                <Tabs defaultActiveKey="patient" id="call-details">
                  <Tab
                    eventKey="patient"
                    title="Patient Details"
                  >
                    <PatientInfo
                      patient={contactCase.patient}
                      case={contactCase}
                      onModified={() => {setDetailsChanged(true)}}
                      onSave={() => {setDetailsSaved(true)}}/>
                  </Tab>
                  <Tab
                    eventKey="dispensary"
                    title={`${contactCase.dispensary.name} Profile`}
                  >
                  <DispensaryInfo dispensary={contactCase.dispensary} />
                  </Tab>
                  <Tab
                    eventKey="patientCases"
                    title="Cases"
                  >
                    <CaseList
                      dispensaryId={(contactCase && contactCase.dispensary)? contactCase.dispensary.id : 0}
                      patientId={contactCase.patient ? contactCase.patient.id : 0}
                      from='CallDetails'
                    />
                  </Tab>
                  <Tab
                    eventKey="products"
                    title="Products"
                  >
                  <ProductsList from='contact'/>
                  <ProductsCart 
                    caseNum={contactCase.caseNumber}
                    />
                  </Tab>
                </Tabs>
              </div>
            ) : (null)
        }
        {contactCase ? <ContactInfo
                          active
                          onClose={() => { return (detailsChanged == detailsSaved) }}
                          showSaveDataWarning={() => {setShowChangesWarning(true)}}
                        /> : (null)}
      </div>
      <DiscardChangesWarning
        onExit={() => setShowChangesWarning(false)}
        onClose={() => setShowChangesWarning(false)}
        show={showChangesWarning}
      />
    </>
  );
});

export default ContactDetail;

