import { faToggleOn, faToggleOff, faCog, faExpand, faCompress } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import '../assets/css/settings.scss'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

const ThemeSwitch = () =>
{
    const [theme,setTheme] = useState(localStorage.getItem('theme') || 'light');
    const [fullscreenView,setFullscreenView] = useState(false);
    const toggleTheme = () => {theme === 'dark'? setTheme('light') : setTheme('dark')}

    const renderTooltipFullSCreen = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Change Theme
        </Tooltip>
    );

    const renderTooltipChangeTheme = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Full Screen
        </Tooltip>
    );
      

    document.onfullscreenchange = (event)=>{
        console.log("FullScreen Event",event)
        if(fullscreenView)
            setFullscreenView(false);
        else
            setFullscreenView(true);
    }

    function fullscreen() {
        if(fullscreenView) {
            document.exitFullscreen();
        }
        else {
            document.body.requestFullscreen();
        }
      }

    useEffect(()=>{
        if(theme === 'dark')
        {
            document.getElementById("root")!.classList.add('dark');
        }
        else
            document.getElementById("root")!.classList.remove('dark')  ;
        localStorage.setItem("theme",theme);
    },[theme])

    
    return (
        <>
        <p className="settingsIcon"><FontAwesomeIcon icon={faCog} /></p>
        <div className="settingsBox">
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipFullSCreen}
            >
                <p onClick={toggleTheme} className="themeSwitch">
                    <FontAwesomeIcon 
                        style={{marginRight: '18px'}}
                        icon={ theme === 'dark' ? faToggleOff : faToggleOn }
                    />
                </p>
            </OverlayTrigger>
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipChangeTheme}
            >
                <p className="fullscreenButton" onClick={fullscreen}>
                    <FontAwesomeIcon icon={fullscreenView === true ? faCompress : faExpand} />
                </p>
            </OverlayTrigger>
        </div>
        </>
        
    )
}

export default ThemeSwitch;