import React, { Component, useEffect, useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { printReservationMethod } from '../../models/utils';

const axios = require('axios');


const ShowReservations = ({onHide, show, caseNum}) => {
  const [reservations,setReservations] = useState<any>([]);


  useEffect(() => {
    var data = JSON.stringify({
      "dispensaryId": 1
    });
     
    var config = {
      method: 'put',
      url: 'https://brq1hlf5m8.execute-api.us-east-1.amazonaws.com/dev/dashboard',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
     
    axios(config)
    .then(function (response) {
      let newReservation = JSON.parse(response.data.reservations);
      const caseReservations = newReservation.filter((item) => (item.caseId === caseNum));
      console.log("Reservation Data New",newReservation);
      console.log("Reservation Data Filter",caseReservations);
      setReservations(caseReservations);
      console.log("Case Number", caseNum);
    })
    .catch(function (error) {
      console.log("Table Data",error);
    });

  },[]);


  return (
      <Modal show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="logDetails"
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Product Reservations</Modal.Title>
        </Modal.Header>
        <Modal.Body className="body-modal" style={{overflowY:'scroll'}}>
          <Card>
            <Card.Body style={{minHeight:'50%'}}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Reserve Time</th>
                    <th scope="col">Item</th>
                    <th scope="col">Count</th>
                    <th scope="col">Method</th>
                    <th scope="col">Rep Name</th>
                    <th scope="col">Case Number</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    reservations.map((item) =>
                      <tr>
                        <td>{item.FirstName}</td>
                        <td>{item.LastName}</td>
                        <td>{new Date(item.ReservationDate).toLocaleDateString()}</td>
                        <td>{item.ProductName}</td>
                        <td>{item.ItemCount}</td>
                        <td>{printReservationMethod(item.Method)}</td>
                        <td>{item.Representative}</td>
                        <td>{item.caseId}</td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
  )
}

export default ShowReservations;