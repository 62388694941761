import React, { useEffect} from 'react';
import { Tab, Tabs, Spinner} from 'react-bootstrap';
import CaseContactInfo from './CaseContactInfo';
import { Case } from '../../models/cases';
import PatientInfo from '../Patients/PatientInfo';
import DispensaryInfo from '../Dispensaries/DispensaryInfo';
import RelatedCaseList from './RelatedCaseList';
import {searchCases} from '../../services/case.service';

type CaseDetailProps = {
  match: {
    params: {
      caseNumber: number;
      dispensaryId: number;
    }
  }
};

const CaseDetail = React.memo<CaseDetailProps>( (props) => {

  const [contactCase, setContactCase ] = React.useState<Case>();

  const getCase = async ()=> {
    const res = await searchCases({
      caseId: props.match.params.caseNumber
    });
    setContactCase(res);
  }

  useEffect(() => {
    getCase();
  }, [props.match]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ marginLeft: '17rem', width: '100%' }}>
      {contactCase ? (
        <>
        <CaseContactInfo caseId={contactCase.caseNumber} />
          <div className="calldetails-slider--grid">
            <Tabs defaultActiveKey="patient" id="call-details">
              <Tab
                eventKey="patient"
                title="Patient Details"
              >
                <PatientInfo patient={contactCase.patient}/>
              </Tab>
              <Tab
                eventKey="dispensary"
                title={`${contactCase.dispensary.name} Profile`}
              >
                <DispensaryInfo dispensary={contactCase.dispensary} />
              </Tab>
              <Tab
                eventKey="patientCases"
                title="Related Cases"
              >
                <RelatedCaseList
                  dispensaryId={contactCase ? contactCase.dispensary.id : undefined}
                  caseId={contactCase ? contactCase.caseNumber : undefined}
                  parentCaseId={contactCase ? contactCase.parentCaseNumber : undefined}
                />
              </Tab>
            </Tabs>
          </div>
        </>) : (<Spinner animation="border" />)
      }
    </div>
  );
});

export default CaseDetail;
