import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhoneAlt,
  faEnvelope,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { Spinner, Modal, Card, Table, Dropdown, Badge } from 'react-bootstrap';
import {
  CaseContext,
  InboundChannel,
  CaseState,
  CaseStatus,
} from '../../models/cases';
import {
  ContactContext,
  AuthContext,
  formatPhoneNumber,
} from '../../models/common';
import { getDispensaryColor } from '../../models/dispensaries';
import { contactContext as _contactContext } from '../../context/contact/contactContext';
import { caseContext as _caseContext } from '../../context/case/caseContext';
import { authContext as _authContext } from '../../context/auth/authContext';
import { printName, printStatus } from '../../models/utils';
import '../../assets/css/cases.scss';

const QueueCaseList = (props) => {
  const caseContext: CaseContext = useContext(_caseContext);
  const contactContext: ContactContext = useContext(_contactContext);
  const authContext: AuthContext = useContext(_authContext);
  const user  = authContext.user!;
  let cases = caseContext[props.caseType];
  const getCases = caseContext.getCases!;
  const clearCases = caseContext.clearCases!;
  const getPatientCases = caseContext.getPatientCases!;
  const getDispensaryCases = caseContext.getDispensaryCases!;
  const initEmail = contactContext.initEmail!;
  const initCall = contactContext.initCall!;
  const { renderCall } = contactContext;
  const [modalShow, setModalShow] = useState(false);
  const [countCase, setCountCase] = useState(0);
  const [casesList, setCasesList] = useState(cases);
  const [filterQueueCase, setFilterQueueCase] = useState('ALL');
  const [filterName, setFilterName] = useState('Filter');

  useEffect(() => {
      if(cases && cases.length && user)
      {
        let casesToBeAdded = cases.filter((eachCase) => {
            if(eachCase.inboundChannel === "EMAIL" && eachCase.updateBy) return (eachCase.updateBy.email === user.email);
            if(eachCase.state === "OUTBOUND" && eachCase.status === "PENDING" && eachCase.assignedCSR.email === user.email) return true;
            if(eachCase.logs && eachCase.logs[0].createdUser)  return (eachCase.logs[0].createdUser.email === user.email)
            return false;
          }
        );
        setCasesList(casesToBeAdded)
      }
  }, [cases])

  const filterQueue = (evt) => {
    setFilterQueueCase(evt);
    setFilterName(evt)
  };

  useEffect(() => {
    casesTable(filterQueueCase)
  }, [filterQueueCase])

  useEffect(() => {
    if(casesList){
      setCountCase(getFilteredData("ALL").length)
    }
  }, [casesList])

  useEffect(() => {
    if (['allCases'].includes(props.caseType)) {
      if(user){getCases(user!.groups[0]);}
    }
    if (['dispensaryCases'].includes(props.caseType)) {
      getDispensaryCases(props.dispensary.id);
    }
    if (['patientCases'].includes(props.caseType)) {
      getPatientCases(
        props.dispensaryId,
        props.patientId,
        props.caseNumber,
        props.parentCaseNumber,
      );
    }
    return () => {
      clearCases();
    };
  }, [props.caseNumber, user]);
  const header = ['allCases', 'dispensaryCases', 'patientCases'].includes(
    props.caseType,
  )
    ? ['Case', 'Type', 'Created Date', 'Patient', 'Dispensary', 'Agent', 'Status']
    : ['Type', 'Patient', 'Dispensary'];

  const renderTableHeader = () =>
    header.map((key) => <th key={key}>{key.toUpperCase()}</th>);

  const getContactName = (contact) => {
    if (contact.patient.firstName) {
      return `${contact.patient.firstName} ${contact.patient.lastName || ''}`;
    }

    if (contact.inboundChannel === 'EMAIL') {
      return contact.inboundEmailAddress;
    }
    if (contact.inboundChannel === 'PHONE') {
      return contact.inboundPhoneNumber === '0'
        ? 'No Phone Number'
        : formatPhoneNumber(contact.inboundPhoneNumber);
    }
    return '';
  };

  const handleCaseClick = (data) => {
    if (
      ['inboundCases', 'outboundCases', 'inProgressCases', 'allCases'].includes(
        props.caseType,
      )
    ) {
      if (data.state === CaseState.OUTBOUND) {
        if (data.inboundChannel === InboundChannel.PHONE) {
          if (!renderCall) {
            setModalShow(true);
          }
          initCall(data, user, 'outbound');
        }
        if (data.inboundChannel === InboundChannel.EMAIL) {
          initEmail(data, user);
        }
      } else if (data.state === CaseState.INBOUND) {
        if (data.inboundChannel === InboundChannel.EMAIL) {
          if (data.status === CaseStatus.IN_PROGRESS) {
            initEmail(data, user);
          }
        }
      }
    }
  };

  const getFormattedDateTimeCSS = (date) => {
    const ts = date.split(/[- :]/);
    const d = new Date(Date.UTC(ts[0], ts[1] - 1, ts[2], ts[3], ts[4], ts[5]));
    return `${d.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
    })}  ${d.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  };

  const tableInTabStyles = {
    backgroundColor: '#fff',
    marginTop: 0,
    border: '1px solid #dee2e6',
    borderTop: 'none',
    borderBottom: 'none',
    borderRadius: '4px',
    borderTopLeftRadius: '0px',
    marginBottom: '-.5rem',
  };

  const getFilteredData = (typeOfCase) => {
    let data;
      data = casesList.filter((dataToBeFiltered) => { 
        return (dataToBeFiltered.status === "IN_PROGRESS" || dataToBeFiltered.status === "PENDING");
      });
      if(typeOfCase != "ALL"){
       return data.filter((filterCategory) => {
          return filterCategory.inboundChannel === typeOfCase;
        })
      }else{
        return data
      }
  };

  const casesTable = (typeOfCase = '') =>
    casesList && casesList.length ? (
      <Card style={{width:"100%"}}>
        <Card.Header style={{textAlign:"center"}}><h5><b>My Cases</b>
        <Badge
              pill
              variant={
                countCase >= 20
                  ? `danger`
                  : countCase > 5 && countCase <= 19
                  ? `warning`
                  : countCase <= 5
                  ? `success`
                  : `success`
              }
              style={{ marginTop: '-16px' }}
            >
              {countCase}
            </Badge>
        
        <div style={{ float: 'right'}}>
              <Dropdown onSelect={filterQueue} drop="left">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  size="sm"
                >
                  {filterName}{` `}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="ALL" selected>
                    All
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="PHONE">Phone Only</Dropdown.Item>
                  <Dropdown.Item eventKey="EMAIL">Email Only</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            </h5>
        </Card.Header>
        <Card.Body style={{padding:"1rem"}}>
        <Table className="cases-table" striped borderless hover>
          <tbody className="cases-table--body">
              <tr
                className="cases-table-headers"
                style={props.caseType !== 'allCases' ? tableInTabStyles : {}}
              >
                {renderTableHeader()}
              </tr>
            {getFilteredData(typeOfCase).length ? getFilteredData(typeOfCase).map((data) => (
              <tr
                className={`cases-table-data border-left-${getDispensaryColor(
                  data.dispensary,
                )} grid-template-columns-${[
                  'allCases',
                  'dispensaryCases',
                  'patientCases',
                ].includes(props.caseType)}`}
                key={data.id}
              >
                {['allCases', 'dispensaryCases', 'patientCases'].includes(
                  props.caseType,
                ) ? (
                  <td className="cases-table-data--case-number">
                    {data.caseNumber}&nbsp;&nbsp;
                  </td>
                ) : null}
                <td
                  className="cases-table-data--type"
                  onClick={() => handleCaseClick(data)}
                >
                  <span className="no-wrap">
                    {data.inboundChannel === 'PHONE' ? (
                      <FontAwesomeIcon icon={faPhoneAlt} />
                    ) : (
                      <FontAwesomeIcon icon={faEnvelope} />
                    )}
                    &nbsp;
                    {data.state === 'INBOUND' ? (
                        <FontAwesomeIcon icon={faLongArrowAltLeft} />                    
                    ) : (
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                    )}
                  </span>
                </td>
                <td className="cases-table-data--name" style={{width:"fit-content"}}>
                {getFormattedDateTimeCSS(data.createdDateTime)}
                </td>
                <td className="cases-table-data--name">
                  <Link
                    to={`/patients/${data.patient.id}/dispensaries/${data.dispensary.id}`}
                  >
                    {getContactName(data)}
                  </Link>
                </td>
                <td
                  className={`cases-table-data--dispensary background-color-${getDispensaryColor(
                    data.dispensary,
                  )}`}
                >
                  <Link to={`/dispensaries/${data.dispensary.id}`}>
                    {data.dispensary.name.slice(0, 12)}
                  </Link>
                </td>
                {['allCases', 'dispensaryCases', 'patientCases'].includes(
                  props.caseType,
                ) ? (
                  <td className="cases-table-data--skill">{printName(data)}</td>
                ) : null}
                {['allCases', 'dispensaryCases', 'patientCases'].includes(
                  props.caseType,
                ) ? (
                  <td className="cases-table-data--status">
                    {data.status && printStatus(data)}
                  </td>
                ) : null}
                {['allCases', 'dispensaryCases', 'patientCases'].includes(
                  props.caseType,
                ) ? (
                  <td>
                    <Link
                      className={`link-color-${getDispensaryColor(
                        data.dispensary,
                      )}`}
                      to={`/dispensaries/${data.dispensary.id}/cases/${data.caseNumber}`}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </Link>
                  </td>
                ) : null}
              </tr>
            )): (<div className="empty-table-message">No cases to display</div>)}
          </tbody>
        </Table>
      </Card.Body>
        </Card>
    ) : (
      <div className="empty-table-message">No cases to display</div>
    );

  return cases ? (
    <div className="cases">
      {casesTable(filterQueueCase)}
      <Modal size="sm" show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Body>You must be online to start a call!</Modal.Body>
        <Modal.Footer className="cases-modal-footer">
          <button
            type="button"
            className="cases-button silver-cases-button"
            onClick={() => setModalShow(false)}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  ) : (
    <div
      className={`table-loading-container cases-margin-left-false`}
    >
      <Spinner animation="border" />
    </div>
  );
};

export default QueueCaseList;

QueueCaseList.propTypes = {
  caseType: PropTypes.string.isRequired,
  dispensaryId: PropTypes.number,
  patientId: PropTypes.number,
  dispensary: PropTypes.shape({ name: PropTypes.string, id: PropTypes.number }),
  caseNumber: PropTypes.number,
  parentCaseNumber: PropTypes.number,
};

QueueCaseList.defaultProps = {
  dispensary: {},
  dispensaryId: null,
  patientId: null,
  caseNumber: null,
  parentCaseNumber: null,
};
