import React from 'react';
import cleanDeep from 'clean-deep';
import axios from 'axios';
import { apiUrl } from '../config/environments';
import { Patient } from '../models/patients';


export interface PatientRequest {
    dispensaryId?: number;
    patientId?: number;
    dispensaryName?: string; 
    patientFirstName?: string;
    patientLastName?: string;
    patientPhone?: string; 
    patinetEmail?: string;
    getCount?: boolean;
    offset?: number;
    limit?: number;
    order?:string;
    orderBy?:string;
};

export const searchPatients = async ( params : PatientRequest)  : Promise<any> => {

    const res = await axios.get(`${apiUrl}/patients`,
    {
      params: {
        ...cleanDeep(params),
      }
    });

    return res.data;
}

export const updatePatient = async (dispensaryId: number, updatedPatient: Patient) : Promise<any> => {
  try {

    const patient = {
      ...updatedPatient,
    };
    patient.dispensary = undefined;
    if (updatedPatient.phoneNumber) {
      patient.phoneNumber = `+${updatedPatient.phoneNumber.replace(/\D/g, '')}`;
    }

    const response = await axios.put(
      `${apiUrl}/dispensaries/${dispensaryId}/patients/${updatedPatient.id}?db=MySQL`,
      cleanDeep(patient),
    );

    return response.data;
  }
  catch(err) {
    ;
  }
  
};

export const deletePatient = async (dispensaryId: number, patientId: number) => {
  return new Promise(async (resolve) => {
    await axios.delete(`${apiUrl}/dispensaries/${dispensaryId}/patients/${patientId}?db=mysql`);
    resolve();
  })
};