import React, { useContext, useState,useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  faUser,
  faThLarge,
  faUsers,
  faCannabis,
  faFileContract,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext, ContactContext, SidePanelContext } from '../../models/common';
import { authContext as _authContext } from '../../context/auth/authContext';
import { contactContext as _contactContext } from '../../context/contact/contactContext';
import { themeContext } from '../../context/theme/themeContext';
import { sidePanelContext as _sidePanelContext } from '../../context/sidePanel/sidePanelContext';
import { CaseSkill } from '../../models/cases';
import {Spinner} from 'react-bootstrap'
import '../../assets/css/switch.scss'; 
import '../../assets/css/sidepanel.scss';


const SidePanel = () => {
  // const {setTheme} = 
  const authContext: AuthContext = useContext(_authContext);
  const { isAuthenticated, resetPasswordRequired, user } = authContext;
  const [agentLoggedStatus, setagentLoggedStatus] = useState("");
  const [displaySpinner, setDisplaySpinner] = useState(false);
  const sidePanelContext: SidePanelContext = useContext(_sidePanelContext);
  const contactContext: ContactContext = useContext(_contactContext);
  const renderPhone = contactContext.renderPhone!;
  const { renderCall } = contactContext;
  const { agentStatus} = sidePanelContext;
  const logout = authContext.logout || (() => { });

  const handleAgentOnlineChange = () => {
      if (!renderCall) {
        setDisplaySpinner(true);
        renderPhone();
    }
  };

  useEffect(() => {
    if(agentStatus === "Offline"){
      setagentLoggedStatus("Available")
    }else{
      setagentLoggedStatus(agentStatus!)
    }

    if(agentStatus){
      setDisplaySpinner(false)
    }

  },[agentStatus])


  useEffect(() => {
    console.debug("displaySpinner", displaySpinner)
  }, [displaySpinner])

  console.log("Theme Context",themeContext);

  const logo = <img src="./images/logo_2019-10-01/logo@3x.png" alt="logo" />;
  return isAuthenticated && !resetPasswordRequired ? (
    <aside className="dashboard-sidepanel">
      <section className="dashboard-sidepanel--user">
        <div className="agent-online-status">
          <h6 className={`agent-online-${renderCall}`}>{renderCall ? 'Online' : 'Offline'} </h6>
          <label htmlFor="online" className="switch" onClick={handleAgentOnlineChange}>
            <input type="checkbox" checked={renderCall}  onChange={() => { }}/>
            <span className="slider round" />
          </label>
        </div>
        {!!user && user.groups && (
          <div className="dashboard-sidepanel--user-image">
            <img src={(user.groups[0] === 'CD') ? "./images/cd_display_picture.jpg" : "./images/supportgirl.jpg"}  style={{ height: '5rem', width: '5rem', color: '#ccc', border:'1px solid #75D317' }}/>
          </div>
        )}
        <div>
        {/* Only For Dev Env */}
          {displaySpinner ? (<Spinner animation="border" />) : agentLoggedStatus ? (<span className="sidepanel-agentstatus">Call Status : {agentLoggedStatus}</span>) : null}
        {/* Only For Dev Env */}
        </div>
        <div style={{marginBottom:'15px'}}>
          {!!user && user.groups && (
            <span className="sidepanel-agentname">
              {user!.email}
            </span>
          )}
          {!!user && user.groups && (
            <span className="sidepanel-agentroll">
              {user!.groups.includes(CaseSkill.CSR)
                ? 'Call Center Representative'
                : 'Clinical Director'}
            </span>
          )}
        </div>
      </section>

      <section>
        <ul className="sidepanel-nav">
          <li>
            <NavLink
              activeClassName="activeNav"
              // activeStyle={{ backgroundColor: '#f7f8fa', color: 'black' }}
              to="/dashboard"
            >
              <FontAwesomeIcon icon={faThLarge} />
              Queue
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="activeNav"
              // activeStyle={{ backgroundColor: '#f7f8fa', color: 'black' }}
              to="/cases"
            >
              <FontAwesomeIcon icon={faUser} />
              Cases
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="activeNav"
              // activeStyle={{ backgroundColor: '#f7f8fa', color: 'black' }}
              to="/patients"
            >
              <FontAwesomeIcon icon={faUsers} />
              Patients
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="activeNav"
              // activeStyle={{ backgroundColor: '#f7f8fa', color: 'black' }}
              to="/dispensaries"
            >
              <FontAwesomeIcon icon={faCannabis} />
              Dispenseries
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="activeNav"
              // activeStyle={{ backgroundColor: '#f7f8fa', color: 'black' }}
              to="/knowledge"
            >
              <FontAwesomeIcon icon={faFileContract} />
              Knowledge
            </NavLink>
          </li>
          <li>
            <p onClick={() => logout()}>
              <FontAwesomeIcon icon={faSignOutAlt} />
              Logout
              </p>
          </li>
        </ul>
      </section>
      <section className="sidepanel-logo">{logo}</section>
    </aside>
  ) : null;
};

export default SidePanel;
