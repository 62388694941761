import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { getDispensaryColor } from '../../models/dispensaries';
import { searchPatients, PatientRequest} from '../../services/patient.service';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Order } from '../../models/common';
import '../../assets/css/patients.scss';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#858b8d',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 700,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

type PatientListProps = {
  dispensaryId?: number;
  from:string;
};

const PatientList = React.memo<PatientListProps>((props) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [patients, setPatients] = React.useState();
  const [counts, setCounts] = React.useState(0);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState('PATIENTLASTNAME');


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const params = React.useMemo<PatientRequest>(() : PatientRequest =>({
    dispensaryId: props.dispensaryId
  }),[props]);

  const getPatients = async ()=> {
      const res : any = await searchPatients({
          ...params,
          offset : page * rowsPerPage,
          limit : rowsPerPage,
          order: order,
          orderBy: orderBy,
          getCount : true           
      });

    setCounts(res.count);
    setPatients(res.records);
  }

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (event) event.preventDefault();
    const isAsc = orderBy === property && order === 'asc';
    setPatients(undefined);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };


  useEffect(() => {
      getPatients();
  }, [page, rowsPerPage, order, orderBy,props]);// eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();

  const patientsTable = () => (
      patients!.length > 0 ?(
        <>
        <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
        <TableHead>
            <TableRow>
                <StyledTableCell key={'PATIENTFIRSTNAME'} align="center" style={{width:'80px'}}
                        sortDirection={orderBy === 'PATIENTFIRSTNAME' ? order : false} >
                <TableSortLabel
                        active={orderBy === 'PATIENTFIRSTNAME'}
                        direction={orderBy === 'PATIENTFIRSTNAME' ? order : 'desc'}
                        onClick={createSortHandler('PATIENTFIRSTNAME')}
                >
                FIRST NAME
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell key={'PATIENTLASTNAME'} align="center" style={{width:'80px'}}
                        sortDirection={orderBy === 'PATIENTLASTNAME' ? order : false} >
                <TableSortLabel
                        active={orderBy === 'PATIENTLASTNAME'}
                        direction={orderBy === 'PATIENTLASTNAME' ? order : 'desc'}
                        onClick={createSortHandler('PATIENTLASTNAME')}
                >
                LAST NAME
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                </TableSortLabel>
              </StyledTableCell>
              {(props.from !=='dispensary' &&
              <StyledTableCell key={'DISPENSARYNAME'} align="center" style={{width:'120px'}}
                    sortDirection={orderBy === 'DISPENSARYNAME' ? order : false} >
                <TableSortLabel
                        active={orderBy === 'DISPENSARYNAME'}
                        direction={orderBy === 'DISPENSARYNAME' ? order : 'desc'}
                        onClick={createSortHandler('DISPENSARYNAME')}
                >
                DISPENSARY
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                </TableSortLabel>
              </StyledTableCell>
              )}
              <StyledTableCell key={'PATIENTPHONE'} align="center" style={{width:'120px'}}
                    sortDirection={orderBy === 'PATIENTPHONE' ? order : false} >
                <TableSortLabel
                        active={orderBy === 'PATIENTPHONE'}
                        direction={orderBy === 'PATIENTPHONE' ? order : 'desc'}
                        onClick={createSortHandler('PATIENTPHONE')}
                >
                PHONE
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell key={'PATIENTEMAIL'} align="center" style={{width:'140px'}}
                    sortDirection={orderBy === 'PATIENTEMAIL' ? order : false} >
                <TableSortLabel
                        active={orderBy === 'PATIENTEMAIL'}
                        direction={orderBy === 'PATIENTEMAIL' ? order : 'desc'}
                        onClick={createSortHandler('PATIENTEMAIL')}
                >
                EMAIL
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell key={'PATIENTMMCC'} align="center" style={{width:'100px'}}
                    sortDirection={orderBy === 'PATIENTMMCC' ? order : false} >
                <TableSortLabel
                        active={orderBy === 'PATIENTMMCC'}
                        direction={orderBy === 'PATIENTMMCC' ? order : 'desc'}
                        onClick={createSortHandler('PATIENTMMCC')}
                >
                MMCC
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell key={'COUNTS'} align="center" style={{width:'80px'}}
                    sortDirection={orderBy === 'COUNTS' ? order : false} >
                <TableSortLabel
                        active={orderBy === 'COUNTS'}
                        direction={orderBy === 'COUNTS' ? order : 'desc'}
                        onClick={createSortHandler('COUNTS')}
                >
                CASE(s)
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align="center" style={{width:'80px'}}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patients!.map((patient : any) => (
              <StyledTableRow key={`${patient.id}-${patient.dispensary.id}`}>
                <StyledTableCell component="th" scope="row" align="center">
                  <span className="patients-table-data">
                    {patient.firstName || ''}
                  </span>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                  <span className="patients-table-data">
                    {patient.lastName ||''}
                  </span>
                </StyledTableCell>
                {(props.from !=='dispensary' &&
                <StyledTableCell align="center">
                  <span className={`patients-table-data--dispensary background-color-${getDispensaryColor(patient.dispensary)}`}>
                  <Link to={`/dispensaries/${patient!.dispensary.id}`}>{patient.dispensary.name.slice(0, 12)}</Link>
                  </span>
                </StyledTableCell>
                )}
                <StyledTableCell align="center">
                  <span className="patients-table-data">
                    {patient.phoneNumber}
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span className="patients-table-data">
                    {patient.emailAddress}
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span className="patients-table-data">
                  {patient.mmccNumber}
                  </span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span className="patients-table-data">
                  {patient.caseCount}
                  </span>
                </StyledTableCell>                        
                <StyledTableCell align="left">
                  <Link
                        className={`link-color-${getDispensaryColor(patient.dispensary)}`}
                        to={`/patients/${patient.id}/dispensaries/${patient.dispensary!.id}`}
                      >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                      </Link>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
            rowsPerPageOptions={[50,100,200]}
            component="div"
            count={counts}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      <div>&nbsp;</div>
        </>
        )
    :(
      <div className="empty-table-message">
        No patients to display
      </div>)
  );
  return (patients? (     
        <div className="patients padding-left">
              {patientsTable()}
            </div>
    )
    :(
        <div className="table-loading-container">
          <Spinner animation="border" />
        </div>
    )
  );
});

export default PatientList;
