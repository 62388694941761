import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap';
import DispensaryInfo from './DispensaryInfo';
import CaseList from '../Cases/CaseList';
import PatientList from '../Patients/PatientList';
import ProductList from '../Products/ProductList';
import { Dispensary } from '../../models/dispensaries';
import { getDispensaries } from '../../services/dispensary.service';

const DispensaryDetail = (props) => {
  const [dispensary, setDispensary] = useState<Dispensary>();
  const dispensaryId = useMemo(()=>parseInt(props.match.params.dispensaryId), [props.match.params.dispensaryId]);

  const searchDispensary = async () => {
    try {
      const res = await getDispensaries({dispensaryId: dispensaryId, offset:0, limit: 1})
      setDispensary(res[0]);
    } catch (err) {
      ;
    }
  }


  useEffect(() => {
    searchDispensary();
  }, [props]);// eslint-disable-line react-hooks/exhaustive-deps

  const dispensaryTabs = () => {
    if (dispensary) {
      return (
        <div className="dispensary-detail-tabs padding-left">
          <Tabs defaultActiveKey="cases" id="dispensary-details-tabs">
            <Tab title="Cases" eventKey="cases">
              <CaseList dispensaryId={dispensary.id || undefined} from='dispensary'/>
            </Tab>
            <Tab eventKey="patient" title="Patients">
              <PatientList dispensaryId={dispensary.id} from='dispensary'/>
            </Tab>
            <Tab eventKey="product" title="Products">
              <ProductList dispensaryId={dispensary.id} from='dispensary'/>
            </Tab>
          </Tabs>
        </div>
      );
    }
    return <div />;
  };
  const dispensaryHeader = () => {
    if (dispensary) {
      return (
        <div className="padding-left dispensary-header">
          <div className="dispensary-info--parent">
            <DispensaryInfo dispensary={dispensary} />
          </div>
        </div>
      );
    }
    return <div />;
  };
  return (
    <div className="disipensary-detail-container">
      {dispensaryHeader()}
      {dispensaryTabs()}
    </div>
  );
};

export default DispensaryDetail;

DispensaryDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      dispensary: PropTypes.object,
      dispensaryId: PropTypes.string,
    }),
  }),
};

DispensaryDetail.defaultProps = {
  match: {
    dispensaryId: 0,
  },
};
