
import React from 'react';
import cleanDeep from 'clean-deep';
import axios from 'axios';
import { apiUrl } from '../config/environments';

export const getDispensaries = async (request : any ) : Promise<any> => {
    try {
      const res = await axios.get(`${apiUrl}/dispensaries`,
        {
          params: {
            ...cleanDeep(request),
            offset: request.offset||0,
            limit: request.limit||10000
          }
        });
      return res.data;
    } catch (err) {
        ;
    }
  };

export const getDispensary = async (dispensaryId: number, request : any) : Promise<any> => {
    try {
      const res = await axios.get(`${apiUrl}/dispensaries/${dispensaryId}`,
      {
        params: {
          ...cleanDeep(request),
          dispensaryId,
          db: 'mySQL',
          offset: request.offset||0,
          limit: request.limit||10000
        }
      });
    return res.data;
    } catch (err) {
      ;
    }
  };
