import { Patient } from './patients';
import { UserSummary } from './users';
import { Dispensary } from './dispensaries';

export interface CaseContext {
  cases?: Array<Case>;
  getCases?: Function;
  getDispensaryCases?: Function;
  pendingCases?: Array<Case>;
  outboundCases?: Array<Case>;
  inProgressCases?: Array<Case>;
  allCases?: Array<Case>;
  allTopics?: Array<Topic>
  createCase?: Function;
  getCase?: Function;
  getDownload?: Function;
  getAllTopics?: Function;
  getPatientCases?: Function;
  clearCases?: Function;
  deleteCase?: Function;
  markDuplicate?: Function;
}

export enum CaseStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CLOSED = 'CLOSED',
}

export enum CaseSkill {
  CSR = 'CSR',
  CD = 'CD',
}

export enum CaseState {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export enum InboundChannel {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  SMS = 'SMS',
}

export enum CaseCategory {
  DISPENSARY = 'DISPENSARY',
  INVENTORY = 'INVENTORY',
  HEALTH = 'HEALTH',
  REGULATORY = 'REGULATORY',
}

export interface CaseCreate {
  inboundChannel?: InboundChannel;
  inboundPhoneNumber?: string;
  inboundEmailAddress?: string;
  assignedCSR?: UserSummary;
  assignedCD?: UserSummary;
  /**
   * Store the full event provided by Amazon Connect Contact Flow.
   */
  inboundCallEvent: any;

  // Properties sent when case created manually
  skill?: CaseSkill;
  state?: CaseState;
}

export interface CaseStatusUpdate {
  status: CaseStatus;
}

export interface UpdateBy {
  email?: string;
  groups: CaseSkill[];
}

export interface Case extends CaseCreate {
  caseNumber: number;
  createdDateTime: string;
  status: CaseStatus;
  dispensary: Dispensary;
  patient: Patient;
  notes: any;
  inboundCallEvent: InboundCallEvent;
  skill: CaseSkill;
  state: CaseState;
  topics: string[];
  category: CaseCategory;
  logs: CaseLog[];
  parentCaseNumber: number;
  updateBy : UpdateBy
}

export interface InboundCallEvent {
  Details: {
    ContactData: {
      Attributes: {}
      Channel: string;
      ContactId: string;
      CustomerEndpoint: {
        Address: string;
        Type: string;
      }
      InititialContactId: string;
      InitiationMethod: string;
      InstanceARN: string;
      MediaStreams: {
        Customer: {
          Audio: string;
        }
      }
      PreviousContactId: string;
      Queue: string;
      SystemEndpoint: {
        Address: string;
        Type: string;
      }
    };
    Parameters: {
      contactCenterPhoneNumber: string;
      inboundPhoneNumber: string;
    };
  }
  Name: string;
}

export interface CaseLog {
  category: string;
  note: string,
  topics?: string[],
  followUp: boolean,
  transfer: boolean,
  skill?: CaseSkill,
  state?: CaseState,
  status?: string,
  createdDateTime?: string,
  email?: Email,
  inboundChannel?: InboundChannel,
  agentUsername?: string,
  callConnectedTimestamp?: string,
  callRecordingS3Location?: string,
  logType?: string,
  parentCase?: number,
  createdUser?: any
}

export interface Email {
  caseId: string,
  date: string,
  html: string,
  patientEmailAddress: string,
  patientName: string,
  subject: string,
  text: string,
  textAsHtml: string,
}

export interface FollowUpCase {
  inboundChannel?: InboundChannel,
  skill?: CaseSkill,
  state?: CaseState,
  parentCaseNumber?: number,
  inboundPhoneNumber?: string,
  inboundEmailAddress?: string,
}

export interface Topic {
  name: string,
  category: string,
}
