import React from 'react';
import { idText } from 'typescript';
import { formatPhoneNumber } from './common';
import { CaseStatus } from './cases'

export const printStatus = (data) => {
  const status = data.status;
  switch (status)
  {
    case CaseStatus.PENDING : 
    {
      if(data.state === 'OUTBOUND') 
        return 'PAFP';
      else
      {
        if(data.skill === 'CD') return 'Escalated'
        else return 'New';
      }
    }
    case CaseStatus.IN_PROGRESS : 
    {
      if(data.state === 'OUTBOUND') return 'PAFP'
      return 'In Progress';
    } 
    default : return 'Resolved'
  }
}

export const printName = (data) => 
{
    if(data.status === 'PENDING') return 'Unassigned'
    const names = {
    'tdadhe@biztransights.com' : "Tejas Dadhe",
    'csr1@biztransights.com' : "John CSR",
    'pmahant@biztransights.com' : "Pravin Mahant",
    'kdas@biztransights.com' : "Kaustav Das",
    'nadia@cannovate.com' : "Nadia Cavasilios",
    'akale@biztransights.com' : "Aditya Kale",
    'cd@cannovate.com' : "Mack CD",
    'csr@cannovate.com' : "Chandler CSR"
    }
    if(data.updateBy && data.updateBy.agentname) return data.updateBy.agentname;
    if(data.logs && data.logs[0].createdUser && data.logs[0].createdUser.email && names[data.logs[0].createdUser.email]) return names[data.logs[0].createdUser.email];
    if(data.updateBy && data.updateBy.email) return names[data.updateBy.email];
    return data.skill;
}

export const printReservationMethod = (method) =>
{
  const values = {
    "IN_STORE_PICK_UP": 'In store pickup',
    "CURBSIDE_PICK_UP": 'Curbside pick up',
    "DELIVERY": 'Delivery'
  }

  return values[method];
}

export const getFormattedDateTime = (date,time=true) => {
    const  ts = date.split(/[- :]/);
    const d = new Date(Date.UTC(ts[0], ts[1]-1, ts[2], ts[3], ts[4], ts[5]));
    return (
      <span>
        <span>
          {`${d.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'numeric',
            year: '2-digit',
          })}`}
        </span>
        {time && 
        <span>&nbsp;&nbsp;{`${d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</span>}
      </span>
    );
  };

export const getContactName = (contact) => {
    if (contact.patient.firstName) {
      return `${contact.patient.firstName} ${contact.patient.lastName || ''}`;
    }

    if (contact.inboundChannel === 'EMAIL') {
      return contact.inboundEmailAddress;
    }
    if (contact.inboundChannel === 'PHONE') {
      return contact.inboundPhoneNumber === '0'
        ? 'No Phone Number'
        : formatPhoneNumber(contact.inboundPhoneNumber);
    }
    return '';
  };

  export const getTimerFromSeconds = (totalSeconds) => {
    let sec = `${totalSeconds % 60}`; if(sec.length < 2) sec = '0' + sec;
    let min = `${Math.floor(totalSeconds / 60) % 60}`; if(min.length < 2) min = '0' + min;
    let hr = `${Math.floor(totalSeconds / 3600) % 60}`; if(hr.length < 2) hr = '0' + hr;
    return `${hr} : ${min} : ${sec}`;
  }

  const minDate = new Date("1990-01-01")
  export const getValiadatedDate = (dt, defaultdt) => {
    if (dt) {
      const date = new Date(dt);
      return date > minDate? date : defaultdt;
    }
    else
      return defaultdt;
  }

