// TODO fix this typescript error
const env = process.env.REACT_APP_ENV||'';
//const env = 'staging';

const environments = {
  dev: [
    {id : 1, name : "Curaleaf"},
    {id : 2, name : "Culta"},
    {id : 3, name : "UK Medicinal"}
  ],
  staging: [
    {id : 4, name : "Curaleaf"},
    {id : 5, name : "Culta"}
  ]
};

export const dispensaries = environments[env];