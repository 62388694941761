import React, { useContext, useEffect, useState } from 'react';
import CallQueue from './CallQueue/CallQueue';
import QueueCaseList from './Queue.CaseList';
import { Card } from 'react-bootstrap';
import { AuthContext, SidePanelContext } from '../../models/common';
import { authContext as _authContext } from '../../context/auth/authContext';
import { CaseContext } from '../../models/cases';
import { caseContext as _caseContext } from '../../context/case/caseContext';
import { sidePanelContext as _sidePanelContext } from '../../context/sidePanel/sidePanelContext';
import '../../assets/css/dashboard.scss';


const Dashboard = () => {
  const authContext: AuthContext = useContext(_authContext);
  const { user } = authContext;
  const caseContext: CaseContext = useContext(_caseContext);
  const getCases = caseContext.getCases!;
  const sidePanelContext: SidePanelContext = useContext(_sidePanelContext);
  const { agentStatus} = sidePanelContext;
  const [displayWarning, setdisplayWarning] = useState(true);

  useEffect(() => {
    if (user) {
      getCases(user!.groups[0]);
    }
    const interval = setInterval(() => getCases(user!.groups[0]), 3000);
    return () => {
      clearInterval(interval);
    };
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(agentStatus){
      setdisplayWarning(false)
    }
  }, [agentStatus])

  return (
    <div className="dashboard padding-left">
         {displayWarning ? (<div style={{marginTop : "2rem"}}>
        <Card style={{ width: '100%'}}>
          <Card.Body style={{color:"red", borderRadius:"5px"}}>
              <p style={{padding:"5px"}}> You must login to AWS Connect By clicking on the Online/Offline Toggle </p>
          </Card.Body>
        </Card>
        </div>) : null}
      <CallQueue />

      <div className="dashboard-cases">
      <QueueCaseList caseType="allCases"/>
      </div>
    </div>
  );
};

export default Dashboard;
