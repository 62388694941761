import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './Reset.css';
import './assets/css/App.scss';

import AppContext from './context';
import PrivateRoute from './components/Auth/PrivateRoute';
import PublicRoute from './components/Auth/PublicRoute';
import setAuthToken from './config/axios';
import Login from './components/Auth/Login';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import SidePanel from './components/SidePanel/SidePanel';
import SoftPhoneContainer from './components/Phone/Container';
import EmailContainer from './components/Email/Container';
import Dashboard from './components/Dashboard/Dashboard';
import CaseDetail from './components/Cases/CaseDetail';
import PatientDetail from './components/Patients/PatientDetail';
import DispensaryList from './components/Dispensaries/DispensaryList';
import DispensaryDetail from './components/Dispensaries/DispensaryDetail';
import Knowledge from './components/Knowledge/Knowledge';
import Settings from './components/Settings/Settings';
import Messages from './components/Messages/Messages';
import ManageCases from './components/Cases/ManageCases.page';
import ManagePatients from './components/Patients/ManagePatients.page';
import Statistics from './components/Statistics/Statistics';
import ThemeSwitch from './components/themeSwitch';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}


const App = () => (
  <div className="App App-logged-in">
    <AppContext>
      <Router>
        <SidePanel />
        <ThemeSwitch />
        <Switch>
          <PublicRoute exact path="/" component={Login} />
          <PublicRoute exact path="/reset" component={ResetPassword} />
          <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/statistics" component={Statistics} />
          <PrivateRoute exact path="/cases" component={ManageCases} caseType="allCases" />
          <PrivateRoute path="/dispensaries/:dispensaryId/cases/:caseNumber" component={CaseDetail} />
          <PrivateRoute exact path="/patients" component={ManagePatients} />
          <PrivateRoute path="/patients/:patientId/dispensaries/:dispensaryId" component={PatientDetail} />
          <PrivateRoute exact path="/dispensaries" component={DispensaryList} />
          <PrivateRoute
            exact
            path="/dispensaries/:dispensaryId"
            component={DispensaryDetail}
          />
          <PrivateRoute path="/knowledge" component={Knowledge} />
          <PrivateRoute path="/settings" component={Settings} />
        </Switch>
        <SoftPhoneContainer />
        <EmailContainer />
        <Messages />
      </Router>
    </AppContext>
  </div>
);

export default App;
