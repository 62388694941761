import React, { Component, useEffect, useState } from 'react';
import { searchProducts, ProductRequest, reserveProducts} from '../../services/product.service';
import {DropdownButton, Dropdown, InputGroup, ListGroup, Toast, Card } from 'react-bootstrap';
import './products.scss';
import { Input } from '@material-ui/core';
import { Product } from '../../models/products';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons"

import axios, { AxiosRequestConfig } from 'axios';



const isAllowedDay = (date) => {
  if(date < new Date()) return
  const day = new Date(date).getDay();
  return day !== 0 && day !== 6;
}

const filterPassedTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() < selectedDate.getTime();
};

let agentData = {
  id: "eaac1a2a-44aa-43f9-9a2b-9974d416e405",
  email: "test_csr1@cannovate.com",
  groups: ["CSR"],
  phoneNumber: "+15550000001",
};

const ProductsCart = ({caseNum, agent=agentData}) => {

  const [state, setState] = useState({val: ''})
  const [items, setItems] = useState([]);
  const [cart,setCart] = useState<Product[]>([]);
  const [results, setResults] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [method, setMethod] = useState('IN_STORE_PICK_UP');
  const [newDate, setNewDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [reservationSuccess, setReservationSuccess] = useState(false)


  useEffect(()=> {
      console.log(state,items,cart);
  },
  [cart])

  const addToCart = (item : Product) => {
    let itemAdded = false;
    let newCart = cart;
    for(var i in cart)
    {
      if(cart[i].id == item.id)
      {
        cart[i].quantity = (cart[i].quantity || 0) + 1;
        itemAdded = true
      }
    }
    if(!itemAdded) {
      item.quantity = 1;        
      item.method = 'IN_STORE_PICK_UP';
      newCart.push(item);
    }
    setCart(newCart);
  }

  const handleChange = (event) => {
    if(event.target.value.length > 2)
    {
      const jsonObj = require('./products-sample.json')
      console.log("JSON Data",jsonObj);
      setResults(jsonObj);
    }
    else
    {
      setResults([]);
    }
  }

  const handleSelect = (index) => {
      addToCart(results[index]);
      setResults([]);
  }

  const handleDateTimeChange = (date,index) => {
    let newCart = cart;
    newCart[index].reservationDate = date;
    setNewDate(date);
    setCart(newCart);  
  }

  const handleMethodChange = (method, index) => {
    let newCart = cart;
    newCart[index].method = method;
    setMethod(method);
    setCart(newCart);
    console.log(newCart);
  }

  const handleQuantityChange = (quantity, index) => {
    let newCart = cart;
    newCart[index].quantity = quantity;
    setQuantity(quantity);
    setCart(newCart);
  }

  const deleteItem = (index) => {
    const newCart = cart;
    newCart.splice(index, 1);
    console.log("New Cart", newCart);
    setNewDate(new Date());
    setCart(newCart);
  }

  const hideToast = () => {
    setShow(false);
  }
  const handleReserve = async (e) => {
    e.preventDefault();
    setLoading(true); 
    reserveProducts(1,caseNum,agent ,cart)
    .then(() => {
      setCart([]);
      setReservationSuccess(true);
    })
    .catch((error) => {
      setReservationSuccess(false);
    })
    .finally(() => {
      setShow(true);
      setLoading(false);
    })
  }


  let data = JSON.stringify({
    "dispensaryId": 1
  });
   
  let config : AxiosRequestConfig = {
    method: 'patch',
    url: 'https://brq1hlf5m8.execute-api.us-east-1.amazonaws.com/dev/dashboard',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
   
  axios(config)
    .then((response) => {
      console.log("Response",JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log("Error",error);
    });

  return (
    <Card className="cart-box">
      <h6 className="text-left mb-3">Make product reservations</h6>
      <input type="text" className="cart-product-search" placeholder="Search for product..."onChange={handleChange}/>
      {
        results.length > 0 && 
          <Dropdown.Menu show className="cart-product-search-result">
            {results.map((item : Product,index)=> { 
              return <Dropdown.Item eventKey={(index).toString()} onSelect={handleSelect}>{item.ProductName}</Dropdown.Item>
            })}
          </Dropdown.Menu>
      }
      {
        cart.length > 0 &&
          <ListGroup className="mt-3 cart-items-list">
            <ListGroup.Item className="cart-item d-flex">
                  <span className="mr-3 flex-grow-1">Item</span>
                  <span className="mr-3 flex-grow-0">Date</span>
                  <span className="mr-3 flex-grow-0">Quantity</span>
            </ListGroup.Item>
            {cart.map((item,index) => {
              return (
                <ListGroup.Item className="cart-item d-flex">
                  <span className="mr-3 flex-grow-1" style={{width:'60%'}}>{item.ProductName}</span>
                  <span className="mr-3">
                    <DatePicker
                      autoComplete="off"
                      selected={cart[index].reservationDate}
                      onChange={(date) => {handleDateTimeChange(date, index)} }
                      showTimeSelect
                      filterDate={isAllowedDay}
                      filterTime={filterPassedTime}
                      dateFormat="MM/dd/yyyy h:mm aa"
                      placeholderText="Select Date and Time"
                    />
                  </span>
                  <div className="">
                    <Input
                      className="item-count"
                      type="text"
                      value={cart[index].quantity}
                      onChange={(e) => {handleQuantityChange(e.target.value, index)}}
                    />
                  </div>
                  <span onClick={() => {deleteItem(index)}} >
                    <FontAwesomeIcon icon={faTrashAlt} style={{marginLeft:'20px',marginRight:'20px'}}/>
                  </span>
                  <Dropdown>
                    <Dropdown.Toggle 
                      className="btnCustom btn btn-outline-info btn-sm" 
                      id="dropdown-basic"
                      style={{ border: 'unset !important', width:'auto' }}
                      value='DELIVERY'
                    >
                      {cart[index].method}
                    </Dropdown.Toggle>

                    <Dropdown.Menu >
                      <Dropdown.Item active onSelect={() => handleMethodChange('IN_STORE_PICK_UP',index)}>In store pick</Dropdown.Item>
                      <Dropdown.Item onSelect={() => handleMethodChange('CURBSIDE_PICK_UP',index)}>Curbside pick up</Dropdown.Item>
                      <Dropdown.Item onSelect={() => handleMethodChange('DELIVERY',index)}>Delivery</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </ListGroup.Item>
              )
            })}
            <div className="d-flex flex-row-reverse">
              <button
                onClick={handleReserve} 
                type="submit" 
                className="case-button green-case-button mt-3" >
                  { loading && 
                      <span 
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                      style={{
                        marginBottom: '3px',
                        marginRight: '10px'
                      }}
                    />
                  }
                  {loading ? "Please Wait" : "Make Reservations"}
              </button>
            </div>
          </ListGroup>
      }
      {
        show && 
        <Toast
            className="outer-container--toast"
            style={{ backgroundColor: `${ reservationSuccess ? 'green' : 'red'}`, color: 'white' }}
            show={show}
            delay={2000}
            onClose={hideToast}
            autohide
          >
        <Toast.Body>{reservationSuccess ? 'Reservation Successful!' : 'Something went Wrong!' }</Toast.Body>
      </Toast>
        }
    </Card>
  );
}

export default ProductsCart;