import React from 'react';
import cleanDeep from 'clean-deep';
import axios, { AxiosRequestConfig } from 'axios';

import { Case, CaseLog } from '../models/cases'
import { apiUrl } from '../config/environments';

export interface CaseRequest {
    dispensaryId?: number;
    caseId?: number;
    status?: string;
    patientFName?: string;
    patientLName?: string;
    inboundPhoneNumber?: string;
    parentCaseId?: number;
    limit?: number;
    offset?: number;
    getCount?: boolean;
    relatedCase?:boolean;
    order?:string;
    orderBy?:string;
};

export const searchCases = async ( params : CaseRequest)  : Promise<any> => {
    const res = await axios.get(`${apiUrl}/cases`,
    {
      params: {
        ...cleanDeep(params),
      }
    });
    return res.data;

}

export const searchRelatedCases = async ( params : CaseRequest)  : Promise<any> => {

  const res = await axios.get(`${apiUrl}/cases`,
  {
    params: {
      ...cleanDeep(params),
      relatedCase: true
    }
  });

  return res.data;
}

export const deleteCase = async (dispensaryId: number, caseNumber: number) => {
  return new Promise(async (resolve) => {
    await axios.delete(`${apiUrl}/dispensaries/${dispensaryId}/cases/${caseNumber}?db=Mysql`);
    resolve();
  })
};

export const getDownload = async (s3Location: string) : Promise<any> => {
  const res = await axios.get(`${apiUrl}/download?s3Location=${s3Location}`);
  return res.data;
};

export const saveCase = async (dispensaryId : number, caseId: number, caseLog: CaseLog) :Promise<any> => {
  try {
    const url = `${apiUrl}/dispensaries/${dispensaryId}/cases/${caseId}`;
    const updatedCase = await axios.post(`${url}/logs`, cleanDeep(caseLog));
    return updatedCase.data;
  } catch (err) {

  }
};

export const getDuplicates = async (checkCaseID : number) =>
{
  return new Promise((resolve,reject) =>
  {
    let apiUrlforDuplicateCase = `${apiUrl}/cases/duplicate`;
    let body = {
      checkCaseID
    }    
    var config: AxiosRequestConfig = {
      method: "patch",
      url: apiUrlforDuplicateCase,
      data: body,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin" : "*",
      },
    };
  
    axios(config)
    .then(function (response) {
      var duplicates = ''
      for(var i in response)
        duplicates += `${response[i].duplicateCaseID}, `;
      resolve(duplicates)
    })
    .catch(function (error) {
      reject(error);
    });
  });
  
}

export const markDuplicate = async (parentCaseID : number, childCaseID : number) =>
{
  let apiUrlforDuplicateCase = `${apiUrl}/cases/duplicate`;
  let body = {
    parentCaseID,
    childCaseID
  }    
  var config: AxiosRequestConfig = {
    method: "post",
    url: apiUrlforDuplicateCase,
    data: body,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin" : "*",
    },
  };

  axios(config)
  .then(function (response) {
    console.log("Marking case" + parentCaseID + "as Duplicate >>" ,JSON.stringify(response.data));
  })
  .catch(function (error) {
    console.log("Marking case" + parentCaseID + "as Duplicate >>" ,error); 
  });
}

