import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Patient } from '../../models/patients';
import { OutboundEmail, formatPhoneNumber } from '../../models/common';
import { Case, CaseLog } from '../../models/cases';
import { User } from '../../models/users';
import { contactContext as ContactContext } from './contactContext';
import contactReducer from './contactReducer';

import {
  RENDER_CALL,
  CONTACT_ERROR,
  CLEAR_ERROR,
  SHOW_CALL,
  END_CONTACT,
  UPDATE_CONTACT_CASE_PATIENT,
  SET_CONTACT_CASE,
  SHOW_EMAIL,
  SEND_EMAIL,
  SAVE_CASE,
  ON_GOING_CALL,

} from '../types';
import connect from '../../config/connect';
import { apiUrl } from '../../config/environments';

const ContactState = (props) => {
  const initialState = {
    // TODO: change to false
    renderCall: false,
    showCall: 0,
    renderEmail: false,
    showEmail: 0,
    contactError: '',
    onGoingCall : false,
  };

  const [state, dispatch] = useReducer(contactReducer, initialState);

  const onGoingCallToggle = (toDo) => {
    dispatch({
      type: ON_GOING_CALL,
      payload : toDo
    });
  }

  const initCall = async (contactCase: Case, user: User, direction: string) => {
       try {
      if (direction === 'inbound') {
        // set agent to routable in order to start answering calls from queue
        const routableState = window.myCPP.agent
          .getAgentStates()
          .filter((s) => s.type === connect.AgentStateType.ROUTABLE)[0];
        window.myCPP.agent.setState(routableState);
      } else if (direction === 'outbound') {
        contactCase = contactCase
        connect.agent((agent) => {
          agent.connect(connect.Endpoint.byPhoneNumber(contactCase.inboundPhoneNumber), {
            queueARN: null,
            success: () => { },
            failure: () => { },
          });
        });
        const url = `${apiUrl}/dispensaries/${contactCase!.dispensary.id}/cases/${contactCase!.caseNumber}`;
        const updatedCase = await axios.post(`${url}/logs`, {
          note: `Call to ${contactCase!.patient.firstName ? `${contactCase!.patient.firstName} ${contactCase!.patient.lastName}` : formatPhoneNumber(contactCase!.patient.phoneNumber)} by ${user!.groups[0]}`,
          status: 'IN_PROGRESS',
          skill: contactCase!.skill,
          logType: 'SYSTEM'
        });
      }
      dispatch({
        type: SET_CONTACT_CASE,
        payload: contactCase,
      });
      if (!state.renderCall) {
        dispatch({
          type: RENDER_CALL,
        });
      } else {
        dispatch({
          type: SHOW_CALL,
        });
      }
    } catch (err) {
      dispatch({
        type: CONTACT_ERROR,
        payload: err.message,
      });
    }
  };

  const completeContact = async (
    contactCase: Case,
    caseLog: CaseLog,
  ) => {
    return new Promise(async (resolve) => {
      try {
        const url = `${apiUrl}/dispensaries/${contactCase.dispensary.id}/cases/${contactCase.caseNumber}`;
        await axios.post(`${url}/logs`, {...caseLog, logType: 'USER'});
        setTimeout(() => {
          dispatch({ type: END_CONTACT });
          resolve()
        }, 1000);
      } catch (err) {
        dispatch({
          type: CONTACT_ERROR,
          payload: err.message,
        });
      }
    })
  };

  const updateContactPatient = async (contactCase: Case, updatedPatient: Patient) => {
    try {
      const patient = {
        ...updatedPatient,
        caseNumber: contactCase ?contactCase.caseNumber:undefined
      };

      if (updatedPatient.phoneNumber) {
        patient.phoneNumber = `+${updatedPatient.phoneNumber.replace(/\D/g, '')}`;
      }

      const dispensaryId =  (updatedPatient.dispensary && updatedPatient.dispensary.id)?
                      updatedPatient.dispensary.id :
                      (contactCase.dispensary.id ?contactCase.dispensary.id:undefined);
      const response = await axios.put(
        `${apiUrl}/dispensaries/${dispensaryId}/patients/${updatedPatient.id}?db=MySQL`,
        patient,
      );
      dispatch({
        type: UPDATE_CONTACT_CASE_PATIENT,
        payload: response.data,
      });
    } catch (err) {
      console.error(err);
      ['emailAddress', 'firstName', 'lastName', 'phoneNumber'].forEach((field) => {
        if (!updatedPatient[field]) {
          dispatch({
            type: CONTACT_ERROR,
            payload: `${field} is required!`,
          });
        }
      });
    }
  };

  const renderPhone = async () => {
    dispatch({
      type: RENDER_CALL,
    });
  };

  const initEmail = async (contactCase: Case, user: User) => {
    try {
      const url = `${apiUrl}/dispensaries/${contactCase.dispensary.id}/cases/${contactCase.caseNumber}`;
      const log = {
        note: `Thread opened by ${user.email}`,
        status: 'IN_PROGRESS',
        skill: contactCase.skill,
        createdDateTime: new Date(),
        logType: 'SYSTEM'
      };

      await axios.post(`${url}/logs`, log);

      dispatch({
        type: SET_CONTACT_CASE,
        payload: { ...contactCase, logs: [log, ...contactCase.logs] },
      });
      dispatch({
        type: SHOW_EMAIL,
      });
    } catch (err) {
      dispatch({
        type: CONTACT_ERROR,
        payload: err.message,
      });
    }
  };

  const sendEmail = async (contactCase: Case, outboundEmail: OutboundEmail) => {
    const url = `${apiUrl}/dispensaries/${contactCase.dispensary.id}/cases/${contactCase.caseNumber}`;
    await axios.post(`${url}/emails`, outboundEmail);

    const date = new Date();
    dispatch({
      type: SEND_EMAIL,
      payload: {
        createdDateTime: date,
        note: 'Email sent to patient',
        email: {
          date,
          patientEmailAddress: contactCase.patient.emailAddress,
          patientName: `${contactCase.patient.firstName} ${contactCase.patient.lastName}`,
          subject: outboundEmail.subject,
          textAsHtml: outboundEmail.bodyHtml,
        },
      },
    });
  };

  const clearError = async () => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };

  const setContactCase = async (contactCase: Case) => {
    dispatch({
      type: SET_CONTACT_CASE,
      payload: contactCase,
    });
  };



  const saveCase = async (
    contactCase: Case,
    caseLog: CaseLog,
  ) => {
    try {
      const url = `${apiUrl}/dispensaries/${contactCase.dispensary.id}/cases/${contactCase.caseNumber}`;
      const updatedCase = await axios.post(`${url}/logs`, { ...caseLog, logType: 'USER'});
      dispatch({
        type: SAVE_CASE,
        payload: {
          ...contactCase,
          ...updatedCase!.data
        }
      });
    } catch (err) {

    }
  };

  return (
    <>
    <ContactContext.Provider
      value={{
        renderCall: state.renderCall,
        showCall: state.showCall,
        initCall,
        initEmail,
        renderPhone,
        completeContact,
        call: state.call,
        updateContactPatient,
        contactCase: state.contactCase,
        renderEmail: state.renderEmail,
        showEmail: state.showEmail,
        sendEmail,
        clearError,
        setContactCase,
        contactError: state.contactError,
        saveCase,
        onGoingCallToggle,
        onGoingCall : state.onGoingCall,
        currentLocation : state.currentLocation
      }}
    >
      {props.children}
    </ContactContext.Provider>
    </>
  );
};

export default ContactState;

ContactState.propTypes = {
  children: PropTypes.shape({}),
};

ContactState.defaultProps = {
  children: {},
};
